/**
 * Colours (PLEASE READ)
 * =======
 * Colours should be given a variable name which describes their appearance
 * first, before being used as values for more semantic variable names.
 *
 * This makes it easier to identify the appearance of a semantic colour variable
 * name, and also lets use reuse colours elsewhere without adding confusion to
 * the semantics of the colour variable name.
 *
 * P.S.: We're trying our best to avoid using the old values as part of the effort
 * to eventually migrate to the new and improved design system which is maintained
 * by the design team and is more consistent and easily accessible through Figma
 */

/**
 * New stuff goes here
 * Do not add anything without first checking the Design System on Figma
 * Talk to the design team if you have any questions
 * Let's try our best to keep this tidy
 */

// Colours 2019 © Romano
// Should be in sync with figma colour palette
//////////// Main palette column 1
$palette--black: #24272e;
$palette--dark-grey: #575d64;
$palette--mid-grey: #575d64bf;
$palette--grey: #7b8389;
$palette--silver: #b5bec5;

//////////// Main palette column 2
$palette--tint-1-grey: #f7f9fa;
$palette--tint-2-grey: #edf1f4;
$palette--tint-3-grey: #e5e9ed;
$palette--tint-4-grey: #dae0e6;

$palette--brand-amaze-gradient: linear-gradient(
  180deg,
  #f0f5fa 24.73%,
  #fbf7fa 100%
);

//////////// Main palette column 3
$palette--wunder-purple: #732dd9;
$palette--gradient-purple: linear-gradient(
  72.87deg,
  #874de7 45.83%,
  #a357de 89.34%
);
$palette--wunder-purple-pale: rgba(115, 45, 217, 0.05);
$palette--wunder-purple-light: rgba(115, 45, 217, 0.3);
$palette--wunder-purple-highlight-element: rgba(115, 45, 217, 0.6);
$palette--wunder-brand-purple: #542ebc;

$palette--lime-green: #47a82d;
$palette--indian-red: #eb5757;
$palette--indian-red--light: #f46e6e;
$palette--warning-orange: #f8883e;
$palette--gold: #e6c347;

//////////// Main palette column 4
$palette--pale-purple: #f5f1fb;
$palette--pale-gradient-purple: linear-gradient(
  72.87deg,
  #ede6fa 25.92%,
  #f1e2f6 89.34%
);
$palette--light-purple: #a874f3;
$palette--light-gradient-purple: linear-gradient(
  72.87deg,
  #ba91fe 45.83%,
  #d49eff 89.34%
);
$palette--pale-blue: #f0f5ff;
$palette--pale-green: #acd8a0;
$palette--pale-red: #fae8e8;
$palette--pale-red-1: #fcf4f4;
$palette--pale-red-2: #f5d8d8;
$palette--pale-orange: #fcf4ee;
$palette--lime-green-dark: #429c2a;
$palette--indian-red-dark: #e04e4e;
$palette--indian-red-light: #f6b3b3;

$palette-grey-gradient-background: linear-gradient(
  236.99deg,
  #f0f5fa 22.35%,
  #fbf7fa 79.39%
);

// Semantic 2019 © Romano
$color-2019--danger: $palette--indian-red;
$color-2019--primary: $palette--lime-green;
$color-2019--font-secondary: $palette--dark-grey;
$color-2019--font-primary: $palette--black;

/**
 * OLD color palette that is all over the place
 * DO NOT add anything else below this point
 */
// Purples
$palette--purple: #732dd9;
$palette--dark-purple: #4f2892;

// Greens
$palette--green: #1fc056;
$palette--light-green: #bbff8e;
$palette--dark-green: darken($palette--green, 5);
$palette--darker-green: darken($palette--green, 10);

// Pinks
$palette--pink: #e71a5d;
$palette--light-pink: #ff8ec6;
$palette--lighter-pink: #eee2ff;
$palette--pink-purple: #a03fc5;

// Dark grays
$palette--black-gray: #343434;
$palette--dark-gray: #747474;
$palette--dark-gray-2: #7c8689;
$palette--dark-gray-3: #41414e;
$palette--dark-gray-4: #515161;
$palette--dark-gray-5: #616172;
$palette--dark-gray-6-low-opacity: rgba(55, 57, 86, 0.1);

// Medium grays
$palette--medium-gray: #aeaeae;
$palette--medium-gray-2: #c9c9c9;
$palette--medium-gray-3: #e2e2e2;

// Light grays / White
$palette--light-gray: #ededed;
$palette--light-gray-2: #f0f0f0;
$palette--light-gray-3: #f5f5f5;
$palette--light-gray-4: #e7f0f3;
$palette--light-gray-5: #f7fafb;
$palette--light-gray-6: #f1f6f7;
$palette--light-gray-7: #f2f7f8;
$palette--light-gray-8: #d7dfe2;
$palette--light-gray-9: #e5e9ed;
$palette--light-gray-10: #dae0e6;
$palette--light-gray-11: #f1f6fb;
$palette--light-gray-12: #dfe9ec;
$palette--pure-white: #ffffff;

// Blue grays
$palette--medium-blue-gray: #a6adc8;
$palette--medium-blue-gray-2: #d6d9e4;
$palette--light-blue-gray: #e4e9f1;
$palette--light-blue-gray-2: #e5e7ef;
$palette--light-blue-gray-3: #e2e9eb;

// Blues
$palette--dark-navy: #1a1a22;
$palette--midnight-blue: #232531;
$palette--facebook-blue: #3b5998;
$palette--blue: #4a90e2;

// Yellows
$palette--yellow: #f0c12d;
$palette--yellow-2: #efc830;

// Colours - Semantic
$color-brand-light: $palette--light-purple;
$color-brand: $palette--purple;
$color-brand-dark: $palette--dark-purple;
$color-action: $palette--green;
$color-action-light: $palette--light-green;
$color-secondary: $palette--light-pink;
$color-light: $palette--medium-gray;
$color-superlight: $palette--light-blue-gray;
$color-bg: $palette--light-gray;
$color-bg-light: $palette--light-gray-2;
$color-bg-dark: $palette--dark-gray;
$color-bg-footer-secondary: $palette--midnight-blue;
$color-bg-white: $palette--pure-white;
$color-bg-white-transparent: rgba(255, 255, 255, 0.75);
$color-input: $palette--light-blue-gray-2;
$color-input-disabled: $palette--light-gray;
$color-facebook: $palette--facebook-blue;
$color-success: $palette--green;
$color-error: $palette--pink;
$color-danger: $palette--pink;
$color-alert: $palette--yellow;
$color-required: $palette--purple;
$color-text: $palette--black-gray;
$color-text-gray: $palette--dark-gray;
$color-text-light-gray: $palette--medium-gray;
$color-text-light: $palette--pure-white;
$color-gray: $palette--light-gray-2;
$color-dark-gray: $palette--medium-gray-2;
$color-share-gray: $palette--dark-gray-2;
$color-light-gray: $palette--light-gray-3;
$color-border-gray: $palette--light-gray-4;
$color-bg-gray: $palette--light-gray-5;
$color-bg-gray-dark: $palette--light-gray-6;
$color-bg-gray-input: $palette--light-gray-7;
$color-warning: $palette--yellow-2;
$color-bg-warning: lighten($palette--yellow-2, 40%);
$color-divider: $palette--light-blue-gray-2;
$color-divider-table: $palette--light-blue-gray-3;
$color-info: $palette--blue;
$color-in-progress: $palette--yellow-2;
$color-footer-bg: $palette--dark-navy;
$color-footer-dropdown-bg: $palette--dark-gray-3;
$color-bg-button-hover: $palette--light-gray-10;
$color-bg-button: $palette--light-gray-9;
$color-bg-header-link-hover: $palette--tint-1-grey;

/**
 * Gradients
 * =========
 */

$color-brand-gradient: linear-gradient(
  30deg,
  $color-brand-dark,
  $color-brand-dark 20%,
  #a61687 75%,
  #a61687
);

$color-brand-gradient-transparent: conic-gradient(
  from 150deg at 50% 50%,
  rgba(64, 34, 147, 0.9) -15deg,
  rgba(84, 46, 188, 0.9) 38deg,
  rgba(88, 51, 188, 0.9) 173deg,
  rgba(64, 34, 147, 0.9) 345deg,
  rgba(84, 46, 188, 0.9) 398deg
);

$color-rainbow-gradient: linear-gradient(
  90deg,
  rgba(255, 0, 0, 1) 0%,
  rgba(255, 154, 0, 1) 10%,
  rgba(208, 222, 33, 1) 20%,
  rgba(79, 220, 74, 1) 30%,
  rgba(63, 218, 216, 1) 40%,
  rgba(47, 201, 226, 1) 50%,
  rgba(28, 127, 238, 1) 60%,
  rgba(95, 21, 242, 1) 70%,
  rgba(186, 12, 248, 1) 80%,
  rgba(251, 7, 217, 1) 90%,
  rgba(255, 0, 0, 1) 100%
);

$start-color-brand-amaze-gradient: #f0f5fa;

$color-brand-amaze-gradient: linear-gradient(
  180deg,
  #f0f5fa 24.73%,
  #fbf7fa 100%
);
$color-smooth-gradient: linear-gradient(
  49.97deg,
  #8457df 25.92%,
  $palette--pink-purple 89.34%
);
$color-smooth-gradient--reversed: linear-gradient(
  49.97deg,
  $palette--pink-purple 25.92%,
  #8457df 89.34%
);
$image-overlay: linear-gradient(0deg, rgba(0, 0, 0, 0.8), transparent 60%);

$background-input-transparent: rgba(0, 0, 0, 0.15);
