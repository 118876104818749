.FrequentlyAskedQuestions {
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  @include lt(medium) {
    display: block;
  }
}

.FrequentlyAskedQuestions-category {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: $spacing--small + $spacing--medium;
  margin-bottom: $spacing--small + $spacing--medium;

  @include lt(medium) {
    padding: 0;
    max-width: none;
    display: none;

    &.active {
      display: block;
    }
  }

  &:nth-child(2n + 1) {
    padding-right: 0;
  }

  @include gt(medium) {
    &.singleContent {
      margin-left: auto;
      margin-right: auto;
      max-width: 80%;
    }
  }
}

.FrequentlyAskedQuestions-category-title {
  font-size: $font-size--d;
  line-height: $line-height--small;
  margin-bottom: $spacing--medium;

  @include lt(medium) {
    display: none;
  }
}

.FrequentlyAskedQuestions-tabs {
  border-bottom: 1px solid $color-divider-table;
  padding-bottom: $spacing--small;
  margin-block-end: $spacing--medium;
  display: flex;

  @include gt(medium) {
    display: none;
  }
}

.FrequentlyAskedQuestions-tab {
  margin-left: $spacing--medium;
  position: relative;
  line-height: $line-height--small;

  @include lt(medium) {
    font-weight: bold;
    color: $palette--dark-gray-2;

    &.active {
      color: $color-text;
    }
  }

  &.active:before {
    content: "";
    position: absolute;
    height: 2px;
    background: $color-success;
    left: 0;
    right: 0;
    bottom: -$spacing--small - 1;
  }

  &:first-child {
    margin-left: 0;
  }
}

.FrequentlyAskedQuestions-item {
  padding-bottom: $spacing--small + $spacing--micro;
  margin-top: $spacing--small + $spacing--micro;
  border-bottom: 1px solid $color-divider-table;
}

.FrequentlyAskedQuestions-item-title {
  font-weight: normal;

  a {
    display: flex;
    cursor: pointer;
    line-height: $line-height--small;

    &:after {
      @include icon($icon--plus);
      color: $color-brand;
      margin-left: auto;
      align-self: top;
      padding-left: $spacing--small;
    }
    .open &:after {
      @include icon($icon--minus);
    }
  }
}

.FrequentlyAskedQuestions-item-content {
  height: 0;
  overflow: hidden;
  width: 80%;
  color: $color-share-gray;
  line-height: $line-height--small;

  .open & {
    height: auto;
    margin-top: $spacing--small + $spacing--micro;
  }

  a {
    color: $color-brand;
  }

  hr {
    margin: 30px 0;
  }

  h4 {
    margin-top: 30px;
  }
}
