.BackButton {
  z-index: 1001;

  &--white {
    background-color: #fff;
  }

  &--translucid {
    background-color: rgba(#111, 0.25);
    border: none;
    box-shadow: none;
  }

  &--transparent {
    .IconButton-icon,
    &.IconButton:hover .IconButton-icon,
    &.IconButton:focus {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }

    .IconButton-label {
      margin-left: 10px;
    }
  }
}
