.CostOverview > div {
  margin-top: $spacing--base * 4;

  &:not(:last-of-type) {
    padding-bottom: $spacing--base * 4;
    border-bottom: 1px solid $color-bg;
  }
}

.CostOverview-itemRow {
  justify-content: space-between;
  flex-direction: row;
  display: flex;

  p {
    color: $palette--dark-grey;
    font-weight: $font-weight--medium;
  }
}

.CostOverview-chargeList {
  margin-top: $spacing--base * 4;
  overflow-wrap: break-word;

  p.CostOverview-chargeTag {
    display: inline-block;
    padding: 6px $spacing--base * 4 6px $spacing--small;
    border-radius: 30px;
    background: $palette--tint-1-grey;
    font-weight: $font-weight--medium;
    white-space: nowrap;
    margin-bottom: $spacing--base * 2;

    &:not(:last-of-type) {
      margin-right: $spacing--base * 2;
    }

    &:before {
      content: url("/public/images/tick.svg");
      margin-right: $spacing--base * 2;
    }
  }
}

.CostOverview-TenantServiceFee {
  display: flex;
  gap: 5px;
  align-items: center;

  @include lt(small) {
    align-items: flex-end;

    & > span {
      width: 42%;
    }
  }
}
