@use "sass:math";

@import "../../styles/variables.scss";
@import "../../styles/variables/spacing.scss";

$default-extra-click-area: 0;

.ExtraAreaButton {
  background: #ffffff;
  border: none;
  border-radius: $button-border-radius;
  box-shadow: none;
  color: $palette--pure-white;
  cursor: pointer;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  @include ms-font-size(minus1);
  height: $button-2019-default;
  line-height: $button-2019-default;
  padding: 0 math.div($spacing--large, 3);
  transition: width 200ms ease-out;

  &:disabled {
    cursor: not-allowed;
  }
}

a.ExtraAreaButton {
  display: inline-block;
}

.ExtraAreaButton--extraAreaEnabled {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    cursor: pointer;
    top: var(--touchAreaTop, $default-extra-click-area);
    left: var(--touchAreaLeft, $default-extra-click-area);
    bottom: var(--touchAreaBottom, $default-extra-click-area);
    right: var(--touchAreaRight, $default-extra-click-area);
  }
}

.ExtraAreaButton--floating {
  box-shadow: $box-shadow-floating-button;
  color: $color-2019--font-primary;
}

.ExtraAreaButton--translucid {
  background: rgba(#111, 0.25);
  box-shadow: none;
}

.ExtraAreaButton--translucid,
.ExtraAreaButton--brand,
.ExtraAreaButton--white {
  &:disabled {
    background: $palette--tint-3-grey;
    color: $palette--silver;
  }
}

.ExtraAreaButton--primary {
  background: $color-2019--primary;

  &:disabled {
    background: $palette--pale-green;
  }
}

.ExtraAreaButton--brand {
  background: $color-brand;
}

.ExtraAreaButton--white {
  background: $color-bg-white;
  color: $color-2019--font-primary;
}
