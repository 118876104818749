.AppHeaderNavigation {
  display: flex;
  align-items: center;
}

.AppHeaderNavigation-List {
  display: flex;
  align-items: center;
}

// Hide language dropdown on small screens.
// Drawer has its own language switch.
.AppHeaderNavigation-ListItem--languageDropdown {
  display: none;

  @include gt(small) {
    display: inline-flex;
  }
}

.AppHeader-SignInLink {
  padding: 9px $spacing--base * 4;
  border-radius: $border-radius-base * 4;

  font-size: $font-size--f;
  font-weight: $font-weight--medium;
}

// SignInLink Theme - Transparent
.AppHeader-theme--transparent .AppHeader-SignInLink {
  background: rgba($color: $palette--pure-white, $alpha: 0.2);
  border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.35);
  color: $palette--pure-white;

  &:hover {
    background: rgba($color: $palette--pure-white, $alpha: 0.3);
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.45);
  }
}
.AppHeader-theme--brandGradient .AppHeader-SignInLink {
  background: rgba($color: $palette--pure-white, $alpha: 0.2);
  border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.35);
  color: $palette--pure-white;

  &:hover {
    background: rgba($color: $palette--pure-white, $alpha: 0.3);
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.45);
  }
}

// SignInLink Theme - White
.AppHeader-theme--white .AppHeader-SignInLink {
  background: $palette--tint-2-grey;
  border: 1px solid $palette--tint-3-grey;
  color: $palette--black;

  &:hover {
    background: $palette--tint-3-grey;
    border: 1px solid $palette--tint-3-grey;
  }
}

// SignInLink Theme - Image
.AppHeader-theme--image .AppHeader-SignInLink {
  color: $palette--pure-white;
  background: rgba($color: $palette--black, $alpha: 0.25);
  border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.25);

  &:hover {
    background: rgba($color: $palette--black, $alpha: 0.35);
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.35);
  }
}
