@mixin boxshadow() {
  box-shadow: rgba(0, 0, 0, 0.4) 1px 0 30px;
}

@mixin triangle($dir, $size, $color) {
  width: 0;
  height: 0;

  @if $dir == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $dir == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else if $dir == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if $dir == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }
}

@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@mixin clearfix() {
  & {
    *zoom: 1;
  }

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}
