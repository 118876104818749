/**
* Buttons
* =======
*/

// Design System 2019 © Romano
$button-2019--small: 26px;
$button-2019--medium: 30px;
$button-2019-default: 36px;
$button-2019--large: 40px;
$button-2019--jumbo: 46px;

$button-width--small: 130px;
$button-width--medium: 165px;
$button-width--large: 230px;
$button-height--small: 30px;
$button-line-height: $button-height--small;
$button-height: 36px;
$button-line-height: $button-height;
$button-height--large: 40px;
$button-line-height: $button-height--large;
$button-border-radius: 20px;
