.InputWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $spacing--large;
  border-bottom: 1px solid $palette--light-gray-9;

  &--disabled {
    border-bottom-color: transparent;
  }
}

.InputWithButton-input {
  font-family: $primary-font-stack;
  font-size: $font-size--c;
  font-weight: $font-weight--bold;
  line-height: $line-height--large;
  flex: 1;
  color: $palette--black;
  background: none;
  border: none;
  box-sizing: border-box;
  border-radius: $spacing--base;
  outline: none;
  background: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-flex;

  &:hover {
    &::placeholder {
      color: $palette--light-gray-10;
    }
  }

  &:focus::placeholder {
    color: $palette--light-gray-10;
  }

  &::placeholder {
    color: $palette--silver;
    font-weight: $font-weight--bold;
    font-size: $font-size--c;
  }

  @include lt(small) {
    &,
    &::placeholder {
      font-size: $font-size--d;
    }
  }

  &--error {
    box-shadow: none;
    color: $palette--indian-red;
    animation: shake 0.5s linear;
  }

  &--disabled {
    display: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.InputWithButton-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  background: $palette--pale-green;
  width: 125px;
  height: $button-height--large;
  border-radius: 25px;
  border: none;
  cursor: pointer;

  @include lt(small) {
    width: $button-2019--large;
    height: $button-height--large;
    min-width: $button-2019--large;
    min-height: $button-height--large;
    border-radius: 50%;
  }

  &--edit {
    width: $button-2019--medium;
    height: $button-2019--medium;
    min-width: $button-2019--medium;
    min-height: $button-2019--medium;
    border-radius: 50%;
    border: 1px solid $palette--light-gray-9;
    background: $palette--tint-2-grey;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.InputWithButton-button--valid.InputWithButton-button--submit {
  background: $palette--lime-green;

  &:hover {
    background: $palette--lime-green-dark;
  }
}

.InputWithButton-btnLabel {
  margin-left: -16px;
  padding: 0 $spacing--medium;

  @include lt(small) {
    display: none;
  }
}

.InputWithButton-arrowRight {
  display: none;

  @include lt(small) {
    display: inline-block;
  }
}

.InputWithButton-errorMsg {
  font-family: $input-font-stack;
  font-size: $font-size--g;
  color: $palette--indian-red;
  margin-top: $spacing--base;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(3em);
  }
  40% {
    transform: translateX(-3em);
  }
  60% {
    transform: translateX(3em);
  }
  80% {
    transform: translateX(-3em);
  }
  100% {
    transform: translateX(0);
  }
}

.InputWithButton-inputContainer {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: $spacing--medium + $spacing--base;
  align-items: center;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transform-origin: center left;
  transition: transform $login-transition-timing $login-transition-function;

  &--disabled {
    transform: scale(0.83, 0.83);
  }
}

.InputWithButton-label {
  display: none;

  &--visible {
    height: $line-height--large;
    font-size: $font-size--d;
    font-family: $primary-font-stack;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: $font-weight--bold;
    line-height: $line-height--large;
  }
}
