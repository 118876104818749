.FlashMessage {
  background: $color-success;
  padding: $spacing--micro $spacing--medium;
  padding-right: $spacing--medium + $spacing--micro;
  font-size: $font-size--f;
  color: white;
  position: relative;
  line-height: $line-height--small;
}

.FlashMessage-closeButton {
  position: absolute;
  top: calc(50% - 11.25px);
  right: 15px;

  background: none;
  outline: none;
  padding: 0;
  border-radius: 2em;
  width: 22.5px;
  height: 22.5px;
  text-align: center;
  color: white;
  border: 0;

  &:hover {
    background: $palette--darker-green;
  }

  &::before {
    @include icon($icon--close);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    margin-left: 1px; // optical adjustment
  }
}
