/**
 * Box shadows
 * ===========
 */

$box-shadow-small-box:
  0px 25px 50px -3px rgba(50, 50, 93, 0.1),
  0px 12px 24px -12px rgba(50, 50, 93, 0.15);
$box-shadow-modal: 0 7px 24px 8px rgba(0, 0, 0, 0.15);
$box-shadow-slight: 0 2px 8px 0px rgba(0, 0, 0, 0.05);
$box-shadow-not-so-slight: 0 2px 8px 0px rgba(0, 0, 0, 0.25);
$box-shadow-over: 0 5px 4px 0 rgba(0, 0, 0, 0.3);
$box-shadow-over-light: 0 4px 5px -2px rgba(0, 0, 0, 0.3);
$box-shadow-popover: 0 1px 4px 2px rgba(0, 0, 0, 0.15);
$box-shadow-card: 0 1px 12px 1px rgba(#c0c0c0, 0.16);
$box-shadow-card--bordered: 1px 2px 2px rgba(0, 0, 0, 0.1);
$box-shadow-card--idle:
  0px 5px 5px 0px rgba(59, 55, 86, 0.03),
  0px 10px 20px 0px rgba(55, 57, 86, 0.1);
$box-shadow-bottom: 0px 13px 25px -5px rgba(0, 0, 0, 0.25);
$box-shadow-button: 0px 3px 2px rgba(0, 0, 0, 0.1);
$box-shadow-floating-button:
  0px 10px 20px rgba(55, 57, 86, 0.1),
  0px 5px 5px rgba(59, 55, 86, 0.03);
$box-shadow-floating-select:
  0px 7px 10px rgba(55, 57, 86, 0.03),
  0px 5px 5px rgba(59, 55, 86, 0.03);
$box-shadow-toaster:
  0px 25px 60px rgba(59, 55, 86, 0.05),
  0px 15px 25px rgba(59, 55, 86, 0.03);
$box-shadow-medium:
  0px 30px 60px -5px rgba(50, 65, 93, 0.1),
  0px 18px 36px -18px rgba(50, 65, 93, 0.15);
$box-shadow-inputSearch: 0 1px 2px rgba(0, 0, 0, 0.1);
$box-shadow-floatingButton:
  0px 5px 5px rgba(59, 55, 86, 0.03),
  0px 10px 20px rgba(55, 57, 86, 0.1);
$box-shadow-focus-iconButton:
  0px 0px 0px 3px white,
  0px 0px 0px 5px rgba(115, 45, 217, 0.5);
$box-shadow-focus-designSystemButtonPrimary:
  0px 0px 0px 3px white,
  0px 0px 0px 5px $palette--pale-green;

/**
 * Text shadows
 * ============
 */

$text-shadow--regular: 0 1px 1px rgba(0, 0, 0, 0.4);
