$switchIndicatorWidth: 46px;
$knobSize: 24px;
$marginSize: 3px;

.Switch-container {
  display: flex;
  flex-direction: column;
  height: $knobSize + $spacing--small;

  &--rightAlign {
    align-items: flex-end;
  }
}

.Switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &--loading {
    cursor: not-allowed;
  }
}

.Switch-toggle {
  display: inline-flex;
  align-items: center;
  width: $switchIndicatorWidth;
  height: $spacing--medium;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  border-radius: 24px;
  background: $color-bg-button;
  transition: background 300ms;

  &:focus-within {
    box-shadow:
      0px 0px 0px 1px rgba(255, 255, 255, 1),
      0px 0px 0px 4px rgba(115, 45, 217, 0.5);
  }

  &--on {
    background: $color-brand;
  }

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    background-size: 10px 10px;
    overflow: hidden;
    border-radius: 24px;
    opacity: 0;
    transition: opacity 300ms;
    background-image: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.1) 50%,
      rgba(0, 0, 0, 0.1) 75%,
      transparent 75%,
      transparent
    );
    animation: load 1.5s infinite;
    animation-timing-function: linear;
  }

  &--on::before {
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.3) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 75%,
      transparent 75%,
      transparent
    );
  }

  &--loading::before {
    transition: opacity 500ms;
    opacity: 1;
  }

  &--loadingOff::before {
    transition: opacity 500ms;
    opacity: 1;
  }
}

@keyframes load {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 50px;
  }
}

.Switch-label {
  font-family: $primary-font-stack;
  font-size: $font-size--f;
  padding-left: $spacing--small;
  transition: color 300ms;
  flex: 1;
  min-width: 0;
  font-weight: $font-weight--medium;

  &--off {
    color: $palette--dark-gray-2;
  }

  &:hover {
    cursor: pointer;
  }
}

.Switch-knob {
  width: $knobSize;
  height: $knobSize;
  position: absolute;
  background: white;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  top: $marginSize;
  border-radius: 50%;
  transform: translateX($marginSize);
  transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &--on {
    transform: translateX($switchIndicatorWidth - $knobSize - $marginSize);
  }
}

.Switch-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.Switch-message {
  display: flex;
  font-size: $font-size--g;
  margin-top: $marginSize + 2px;
  opacity: 0;
  min-height: 1.5em;
  transition: opacity 300ms;
  justify-content: flex-start;

  &--error {
    color: $palette--indian-red;
    opacity: 1;
  }

  &--success {
    color: $palette--lime-green;
    opacity: 1;
  }

  &--rightAlign {
    justify-content: flex-end;
  }
}
