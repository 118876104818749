.RegistrationNumberPopover-closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
}

.RegistrationNumberPopover-content {
  padding: $spacing--small + $spacing--base;

  @include gt(medium) {
    max-width: 335px;
    padding: 0;
  }
}

.RegistrationNumberPopover-contentTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: $font-weight--bold;
  font-size: $font-size--d;
  color: $palette--black;
  margin-bottom: $spacing--base * 5;
}

.RegistrationNumberPopover-contentInfo {
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: $line-height--small;
  color: $palette--black;
  margin-bottom: $spacing--small;
}

.RegistrationNumberTitlePopover-toggleButton {
  display: flex;
  align-items: center;

  cursor: pointer;

  background: none;
  outline: none;
  border: 0;
  margin-bottom: $spacing--base * 2;

  & h4 {
    @include typo--heading-4;
    color: $palette--black-gray;
  }

  & svg {
    margin-left: $spacing--base * 2;
  }
}

.RegistrationNumberBodyPopover-toggleButton {
  cursor: pointer;

  background: none;
  outline: none;
  border: 0;

  text-decoration: underline;
  font-family: Poppins;
  font-weight: $font-weight--medium;
  font-size: $font-size--e;
  color: $palette--dark-grey;

  max-width: 90vw;
  text-align: left;
}

.RegistrationNumberBodyPopover-registrationNumbersContainer {
  margin-top: $spacing--small;
  margin-bottom: $spacing--medium;
}

.RegistrationNumberBodyPopover-seperator {
  border: 0;
  border-top: 1px solid $palette--tint-3-grey;
  margin-bottom: $spacing--small;
}

.RegistrationNumberBodyPopover-registrationNumber {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  color: $palette--black;
}
