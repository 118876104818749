.FeaturedCompanies {
  text-align: center;
  padding: 75px 0;

  &-title {
    max-width: 750px;
    margin: 0 auto 50px;
  }

  &-wrapper {
    text-align: center;
  }

  &-feature {
    margin-bottom: 50px;
    padding: 0 10px;
    display: inline-block;
    vertical-align: middle;

    img {
      width: 200px;
      padding: 0 20px 0 20px;
    }
  }
}
