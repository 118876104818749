.AppHeaderDrawer {
  padding-top: $spacing--base * 4;

  &-children {
    padding: 0;
  }
}

.AppHeaderDrawer-avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;

  border-radius: 50%;

  margin: 0 auto;

  font-size: 24px;
  font-weight: $font-weight--bold;
  text-transform: uppercase;

  color: #f9fafb; // It doesn't have variable
  background: $palette--gold;
}

.AppHeaderDrawer-name {
  font-size: 24px;
  font-weight: $font-weight--bold;
  text-align: center;
  color: $palette--black;

  margin-top: $spacing--base * 2;
}

.AppHeaderDrawer-warning {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  line-height: 21px;
  text-align: center;
  color: $palette--dark-grey;

  max-width: 256px;
  margin: 3px auto 0 auto;
}

.AppHeaderDrawer-LinkList {
  margin-top: $spacing--base * 7;
}

.AppHeaderDrawer-LinkList-item {
  margin: 0 $spacing--base;
}

.AppHeaderDrawer .AppHeader-Link {
  flex-wrap: wrap;
  padding: $spacing--small;
  padding-bottom: 0;

  &:after {
    content: "";
    width: 100%;
    border-bottom: 1px solid $palette--tint-2-grey;
    margin-top: 14px;
  }

  &--active {
    background: $palette--tint-2-grey;
    border-radius: $border-radius-base;
  }

  &-text {
    font-size: $font-size--e;
    font-weight: $font-weight--bold;
    color: $palette--black;
  }

  &-badge {
    width: 26px;
    height: 26px;
    background: $palette--wunder-purple;

    font-weight: $font-weight--medium;
    font-size: $font-size--g;
    color: $palette--pure-white;
  }
}

.AppHeaderDrawer-LinkList-item {
  margin-top: -1px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child > .AppHeader-Link::after {
    border: none;
  }
}

.AppHeaderDrawer-LinkList-bottom {
  border-top: 1px solid $palette--tint-3-grey;
  background: $palette--tint-1-grey;
  padding-bottom: $spacing--base * 4;
  margin-top: $spacing--small;

  .AppHeader-Link-text {
    font-size: $font-size--f;
    font-weight: $font-weight--medium;
  }

  // Logout link
  .AppHeader-Link-logoutLink .AppHeader-Link-text {
    color: $palette--indian-red;
  }
}

.AppHeaderDrawer-signInButton {
  text-align: center;
  padding: 0 $spacing--base * 4;

  &-link {
    width: 100%;
    height: 46px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-size: $font-size--e;
    font-weight: $font-weight--medium;
    color: $palette--pure-white;

    background: $palette--lime-green;
    border-radius: $border-radius-base * 5;
  }
}
