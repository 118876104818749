@import "../../styles/variables.scss";
@import "../../styles/media.scss";

@import "./AppHeaderNavigation";
@import "./DesktopNavigationLinks/DesktopNavigationLinks";
@import "./LanguageDropdown/LanguageDropdown";
@import "./AppHeaderUserMenu/AppHeaderUserMenu";

.AppHeader {
  display: flex;
  align-items: center;

  width: 100%;
  height: $height-app-header;
  padding: 0 $spacing--base * 3;

  &-children {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    margin-left: $spacing--base * 3;
    margin-right: $spacing--base * 3;

    .GeocodingInputSearch {
      max-width: $geocodingInputSearch-appHeader-maxWidth;

      @include gt(medium) {
        max-width: $geocodingInputSearch-appHeader-maxWidth--medium;
      }
    }

    @include gt(small) {
      margin-right: 0px;
    }

    @include gt($homePage-breakpoint--medium) {
      margin-left: $spacing--base * 4;
    }

    @include gt($homePage-breakpoint--large) {
      margin-left: $spacing--base * 5;
    }
  }

  @include lt(small) {
    height: $height-app-header--mobile;
  }

  @include gt(large) {
    padding: 0 $spacing--medium;
  }
}

.AppHeader-container {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: left;
  justify-content: space-between;
}

.AppHeader-brand {
  display: flex;
  align-items: center;
}

.AppHeader-backButton {
  width: 36px;
  height: 36px;
  outline: none;
}

// Themes

.AppHeader-theme--basicTransparent {
  background: none;

  .AppHeader-container--border {
    border: none;
  }

  .WunderflatsLogo-lettering {
    fill: $palette--black;
  }
}

.AppHeader-theme--transparent {
  background: none;

  .AppHeader-container--border {
    border-bottom: 1px solid rgba($color: $palette--tint-3-grey, $alpha: 0.25);
  }

  .WunderflatsLogo-shape {
    fill: $palette--pure-white;
  }

  .WunderflatsLogo-lettering {
    fill: $palette--pure-white;
  }

  .WunderflatsLogoBadge-shape {
    fill: $palette--pure-white;
  }

  .AppHeader-WunderflatsLogo .logo {
    fill: #fff;
  }
}

.AppHeader-theme--white {
  background: $palette--pure-white;

  .AppHeader-container--border {
    border-bottom: 1px solid rgba($color: $palette--tint-3-grey, $alpha: 0.95);
  }

  .WunderflatsLogo-lettering {
    fill: $palette--black;
  }
}

.AppHeader-theme--image {
  background: none;

  .AppHeader-container--border {
    border-bottom: 1px solid rgba($color: $palette--pure-white, $alpha: 0.25);
  }

  .WunderflatsLogo-shape {
    fill: $palette--pure-white;
  }

  .WunderflatsLogo-lettering {
    fill: $palette--pure-white;
  }

  .WunderflatsLogoBadge-shape {
    fill: $palette--pure-white;
  }

  .AppHeader-backButton {
    background: rgba($color: $palette--black, $alpha: 0.25);
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.25);
  }

  .AppHeader-WunderflatsLogo .logo {
    fill: #fff;
  }

  .AppHeader-WunderflatsLogoBadge {
    .clipPath,
    .clipPath-second {
      fill: #fff;
    }
  }
}

.AppHeader-theme--brandGradient {
  background: linear-gradient(
      155.25deg,
      rgba(107, 37, 223, 0) 48.74%,
      rgba(129, 34, 226, 1) 92.9%
    ),
    linear-gradient(
      335.16deg,
      rgba(107, 37, 223, 0) 55.62%,
      rgba(88, 38, 228, 1) 99.6%
    ),
    linear-gradient(
      50.88deg,
      rgba(107, 37, 223, 0) 28.21%,
      rgba(134, 34, 227, 1) 83.2%
    ),
    rgba(108, 34, 222, 1);

  .AppHeader-container--border {
    border-bottom: 1px solid rgba($color: $palette--tint-3-grey, $alpha: 0.25);
  }

  .WunderflatsLogo-shape {
    fill: $palette--pure-white;
  }

  .WunderflatsLogo-lettering {
    fill: $palette--pure-white;
  }

  .WunderflatsLogoBadge-shape {
    fill: $palette--pure-white;
  }

  .AppHeader-WunderflatsLogo .logo {
    fill: #fff;
  }

  .AppHeader-WunderflatsLogoBadge {
    .clipPath,
    .clipPath-second {
      fill: #fff;
    }
  }
}

// AppHeaderLink skeleton
.AppHeader-Link {
  display: flex;
  align-items: center;

  &-icon {
    display: inline-flex;
    margin-right: 16px;
  }

  &-text {
    font-weight: $font-weight--medium;
    font-size: $font-size--f;
    line-height: 22px;
  }

  &-badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-size: $font-size--h;
    font-weight: $font-weight--medium;

    width: 22px;
    height: 22px;

    border-radius: 50%;

    margin-left: 6px;
  }
}

.AppHeader-Link--expand {
  .AppHeader-Link-badge {
    margin-left: auto;
  }
}

// Dropdown - React Transition Group
.AppHeader-DropdownTransition {
  &-enter {
    opacity: 0;
    transform: translateY(-5px);
    transition: all 150ms;
  }

  &-enter-active {
    opacity: 1;
    transform: translateY(0);
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
    transition: all 150ms;
  }

  &-exit-active {
    opacity: 0;
    transform: translateY(-5px);
  }
}
