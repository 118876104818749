@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/icons.scss";
@import "../../../../styles/media.scss";
@import "../../../../styles/animation.scss";

@import "./MonthlyAvailabilityViewer/MonthlyAvailabilityViewer";
@import "./ImageGalleryModal/ImageGalleryModal";
@import "./StartCreateBookingWizardForm/StartCreateBookingWizardForm";
@import "./StartCreateBookingWizardForm/ListingPriceText";
@import "./MultilistingUnavailableCard/MultilistingUnavailableCard";
@import "./CostOverview.scss";
@import "./VerifiedBadge.scss";
@import "./ViennaStayDurationMessage.scss";
@import "./RegistrationNumber.scss";

$hero-image-height--mobile: 400px;
$height-mobileCallOut: 70px;
$animationTravelLength: 200px;
$booking-wizard-part-height: 310px;

.ListingDetails {
  font-family: $primary-font-stack;

  .ServicesList-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .ServicesList-serviceItemOuter {
    display: flex;
    align-items: center;
  }

  .ServicesList-serviceDetail {
    color: $palette--dark-grey;
    font-size: $font-size--g;
    line-height: $line-height--micro;
  }

  .ListingDetails-shortlistButton {
    position: absolute;
    bottom: $spacing--small - 3;
    right: $spacing--small;
    height: $button-2019-default;
    width: $button-2019-default;
  }

  .ListingDetails-floorPlanButton {
    display: flex;
  }

  .ListingDetails-desktopButtons {
    .ListingDetails-viewGalleryButton {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ListingDetails-viewGalleryText {
      margin-left: $spacing--micro;
    }

    @include lt(large) {
      display: none;
    }

    @media (min-width: 993px) {
      align-content: center;
      display: flex;
      position: relative;
      top: -65px;
    }
  }

  .ListingDetails-shortlistButtonDesktop {
    height: 36px;
    margin-left: $spacing--small;
  }

  .ListingDetails-floorPlanButton {
    display: flex;
    align-items: center;
    margin-left: $spacing--small;
  }

  .ListingDetails-floorPlanText {
    margin-left: $spacing--micro;
  }

  &-mobileFixedButtonsOuter {
    display: flex;
    position: fixed;
    top: $spacing--small;
    z-index: 1001;
    justify-content: space-between;
    padding: 0 $spacing--medium 0 0;
    width: 100%;
    height: 0;
    overflow: visible;
  }

  &-mobileFixedButton {
    transform: translateY(-62px);
    transition: transform 0.3s cubic-bezier(0.07, 0.68, 0.48, 1.51);

    &--visible {
      transform: translateY(0px);
      box-shadow: $box-shadow-floating-button;
      border: 1px solid $color-bg;
    }

    &.delayed {
      transition-delay: 0.1s;
    }
  }

  &-mobileFixedShortlistButton {
    position: fixed;
    top: $spacing--small;
    right: $spacing--small;
    z-index: 1001;
  }

  &-mobileFixedBackButton {
    position: fixed;
    top: $spacing--small;
    left: $spacing--small;
    width: 40px;
  }

  .BookingWizardVisible & .AppHeader {
    display: none;
  }

  .ImageGallery-navButton {
    &--left {
      justify-content: flex-start;
      width: 5%;
      padding-left: 20px;
    }

    &--right {
      justify-content: flex-end;
      width: 30%;
      padding-right: 20px;
      z-index: 0;
    }
  }

  &-toolbar {
    z-index: 2;
    margin-top: $spacing--base * 3;
    font-size: 0.8em;
    position: absolute;
    top: $height-app-header;
    right: 30px;
    padding: 0.5em 1em;
    border-radius: 2em;
    color: $color-light;
    background: rgba(black, 0.7);
    a {
      font-weight: bold;
      color: white;
    }

    @include lt(large) {
      top: $height-app-header--mobile;
      right: 1em;
      font-size: $font-size--f;
    }

    @include lt(small) {
      top: $hero-image-height--mobile;
      transform: translateY(calc(-100% - #{$spacing--small}));
      right: $spacing--small;
      margin-top: 0;
    }
  }

  .stick-it {
    display: inline-block;
    border-radius: 7px;

    .bookingTeam,
    .BookingForm,
    .StartCreateBookingWizardForm {
      position: relative;
      top: 0;
    }
  }

  .bookingTeam {
    background: linear-gradient(180deg, #f0f5fa 24.73%, #fbf7fa 100%);
    text-align: center;
    width: 300px;
    border-radius: 0 0 7px 7px;
    padding: 30px;

    &-title {
      color: $color-text;
      margin-top: 12px;
      margin-bottom: 5px;
      font-weight: $font-weight--bold;
      font-style: normal;
      font-size: $font-size--f;
      line-height: 21px;
      text-align: center;
    }

    &-info {
      font-size: $font-size--g;
      color: $palette--grey;
      line-height: 16px;
    }

    img {
      border: 1px solid #ccc;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      object-fit: cover;
    }

    .name {
      margin-top: $spacing--base;
      margin-bottom: 2px;
      font-size: 16px;
    }

    .contactInfo {
      margin-top: 1.6em;
    }

    .contactButton {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      font-weight: $font-weight--bold;
      font-size: $font-size--f;
      border: 2px solid $palette--tint-4-grey;
      color: $palette--black-gray;
      background: $palette--pure-white;
    }

    .phone {
      overflow: hidden;
      opacity: 0.7;
      height: 36px;
      line-height: 36px;
      vertical-align: middle;

      &::before {
        @include icon($icon--telephone);
        display: inline-block;
        margin-top: 2px;
        margin-right: 0.3em;
        font-size: 1.2em;
        font-weight: normal;
        transform: translateY(1px) scaleX(-1);
      }
    }
  }

  .heading-large {
    margin: $spacing--base * 3 0;

    @include gt(medium) {
      margin-top: $spacing--base * 5;
      margin-bottom: $spacing--small;
    }
  }

  article {
    section > h3 {
      margin: 0 0 $spacing--medium;
      font-size: 20px;
      font-weight: bold;
      color: $color-text;
    }

    p {
      overflow-wrap: break-word;
      white-space: pre-line;
    }

    section {
      padding: $spacing--minor * 2 0;
      border-bottom: 1px solid $color-bg;
      white-space: pre-line;

      @include gt(medium) {
        padding: 50px 0;
      }
    }

    @include lt(small) {
      section > h3 {
        margin: 0 0 $spacing--small;
      }
    }
  }

  header .ListingPrice {
    position: relative;
    top: -18px;
    font-size: 20px;
  }

  &-image {
    cursor: pointer;
    height: 600px;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  &-wrapper {
    position: relative;
    max-width: $global-width--max-width;
    margin: 0 auto;
    padding: 0 15px 60px;
    display: flex;
    gap: 30px;

    @media (max-width: 993px) {
      gap: 0;
      flex-direction: column;
    }
  }

  &-basic {
    line-height: 20px;
    display: flex;
    align-items: center;

    .Rating {
      float: right;
    }

    .txt-light {
      color: $palette--dark-grey;
      display: inline-block;
      font-size: $font-size--f;
      font-weight: $font-weight--medium;
      padding-left: $spacing--base;
    }
  }

  &-stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-top: $spacing--minor * 2;

    @include gt(medium) {
      display: flex;
      justify-content: space-between;
      width: 85%;
    }
  }

  .ListingDetails-statsElt {
    display: flex;
    position: relative;
    align-items: center;

    &::before {
      background-color: $palette--tint-2-grey;
      background-size: $spacing--medium $spacing--medium;
      border-radius: 50%;
      margin-right: $spacing--small;
      height: 46px;
      width: 46px;
    }

    @include lt(small) {
      &:before {
        margin-left: 0;
        margin-right: 12px;
      }

      .groundFloor {
        display: block;
      }
    }

    &.floor::before {
      @include svgIcon($icon--apartment-size);
    }

    &.rooms::before {
      @include svgIcon($icon--room);
    }

    &.beds::before {
      @include svgIcon($icon--bed);
    }

    &.wifi::before {
      @include svgIcon($icon--wifi);
    }

    &.guests::before {
      @include svgIcon($icon--guests);
    }

    .explanation {
      display: none;
      position: absolute;
      bottom: 2px;
      left: 90px;
      font-size: 0.8em;
      color: $color-light;

      @include lt(small) {
        position: relative;
        left: auto;
        bottom: auto;
        @include ms-font-size(minus3);
        line-height: $line-height--micro;
      }
    }

    .touch & .explanation {
      display: block;
    }

    .non-touch &:hover .explanation {
      display: block;
    }
  }

  .ListingDetails-statsText {
    white-space: nowrap;
    display: block;
    color: $palette--black;
    font-weight: $font-weight--medium;
    font-size: $font-size--e;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;

    @include lt(xsmall) {
      font-size: $font-size--f;
    }
  }

  &-about-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: $spacing--small + $spacing--base;
    white-space: pre-wrap;

    @include gt(small) {
      flex-direction: initial;
      align-items: center;
    }
  }

  &-services {
    .check {
      margin: 0 0 10px;
      padding: 0 50px 0 0;
      line-height: 24px;

      &::before {
        float: left;
        @include icon($icon--circled-tick);
        margin-right: 10px;
        font-size: 24px;
        color: $color-success;
      }
    }

    .unavailable {
      margin: 0 0 $spacing--base * 2;
      padding: 0 $spacing--base * 10 0 0;
      line-height: 24px;

      &::before {
        float: left;
        @include icon($icon--circled-close);
        margin-right: $spacing--base * 2;
        font-size: 24px;
        color: $palette--grey;
      }
    }
  }

  &-amenities,
  &-roomsInformation {
    overflow: hidden;
  }

  .BedsList {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    row-gap: $spacing--minor;
    column-gap: $spacing--medium;

    @media (max-width: 993px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 376px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &-category {
      background: #f7f9fa;
      border-radius: 7px;
    }

    &-category {
      padding: $spacing--minor;
    }

    &-categoryTitle {
      font-size: inherit;
      font-weight: $font-weight--bold;
      display: flex;
      align-items: center;
      margin-top: 36px;
    }

    &-categorySubtitle {
      color: $palette--dark-gray-5;
      font-weight: $font-weight--medium;
    }

    .doubleBedCount::before {
      @include svgIcon($icon--double-bed);
    }

    .singleBedCount::before {
      @include svgIcon($icon--single-bed);
    }

    .couchBedCount::before {
      @include svgIcon($icon--couch-bed);
    }

    .guestBedCount::before {
      @include svgIcon($icon--bunk-bed);
    }

    .othersCount::before {
      @include svgIcon($icon--other-bed);
    }

    .doubleBedCount,
    .singleBedCount,
    .couchBedCount,
    .guestBedCount,
    .othersCount {
      &::before {
        position: absolute;
        height: 25px;
        width: 25px;
      }
    }
  }

  .AmenitiesList {
    &-columns {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-auto-rows: minmax(min-content, max-content);
    }

    &-category {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &-categoryTitle {
      font-size: inherit;
      font-weight: $font-weight--bold;
      min-height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .amenity {
    display: flex;
    align-items: center;
    min-height: 40px;
    line-height: $line-height--small;

    &:before {
      color: $color-success;
      margin-right: 10px;
      @include icon($icon--circled-tick-large);
      font-size: 24px;
      line-height: 24px;
    }
  }

  .amenity-subtitle {
    display: block;
    color: $color-text-gray;
    font-size: 12px;
    line-height: $line-height--micro;
    margin-top: -1px;
  }

  &-map {
    height: 500px;
  }

  @include lt(large) {
    .BookingForm,
    .StartCreateBookingWizardForm {
      label,
      legend {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    .StartCreateBookingWizardForm {
      width: auto;

      label {
        max-width: none;
      }
    }

    .BookingForm,
    .bookingTeam {
      width: 100%;
      text-align: center;
      border-radius: 7px;
      margin-top: $spacing--base * 2;

      button,
      .contactButton {
        max-width: 240px;
        margin: 0 auto;
      }
    }

    &-map {
      height: 400px;
    }

    .AmenitiesList {
      &-columns {
        grid-template-columns: minmax(160px, 1fr) minmax(160px, 1fr);
      }
    }

    .Footer-container {
      padding-bottom: $height-mobileCallOut + $spacing--small;
    }

    .AppHeader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;

      z-index: 115;
    }
  }

  @include lt(xsmall) {
    &-amenities,
    &-roomsInformation {
      ul {
        min-width: 160px;
        width: 100%;
      }
    }
  }

  @include lt(small) {
    .ListingDetails-title {
      font-size: $font-size--c;
      line-height: $line-height--medium;
      word-wrap: break-word;
    }

    &-stats {
      span {
        text-align: center;

        &::before {
          float: none;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  @media (min-width: 993px) {
    &-stats {
      .ErrorIndicator-message {
        padding-left: 80px;
      }
    }
  }

  @include lt(large) {
    &-listingTitle {
      font-size: 1.6em;
    }

    .no-touch {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }

    &-image {
      height: $hero-image-height--mobile;
      overflow: hidden;
      -webkit-tap-highlight-color: transparent;

      &:before {
        content: "";
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.35;
        z-index: 1;
      }

      &.ListingDetails-image--multilisting:before {
        height: 185px;
      }
    }

    &-imageElement {
      width: calc(100% + #{$animationTravelLength});
      @include lt(small) {
        animation: coverImageAnimation 18s linear infinite alternate-reverse;

        @media (prefers-reduced-motion: reduce) {
          animation: none;
        }
      }
    }
  }

  .ListingDetails-bookingSidebar {
    width: auto;
    position: sticky;
    height: max-content;
  }

  .ListingDetails-bookingSidebar--stickyTop {
    // !!!!IMPORTANT these position values here are such because they make the booking sidebar aligned with cover image
    top: 400px;
    transform: translateY(-332px);
    @media (max-width: 993px) {
      position: unset;
      transform: unset;
      top: 0;
    }
  }

  .ListingDetails-bookingSidebar--stickyFix {
    // !!!!IMPORTANT these position values here are such because they make the booking sidebar aligned with cover image
    top: 68px;
    transform: unset;
    @media (max-width: 993px) {
      position: unset;
      transform: unset;
      top: 0;
    }
  }

  @include eq(medium) {
    .ListingDetails-bookingSidebar {
      overflow: hidden;
      display: flex;

      button,
      .contactButton {
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
      }

      legend {
        width: 100%;
        text-align: center;
      }

      .BookingForm,
      .StartCreateBookingWizardForm {
        flex: 1;
        min-width: 300px;
        border-radius: 0;
        text-align: center;
      }

      .bookingTeam {
        flex: 1;
        padding-left: $spacing--medium;
        width: auto;
        border-radius: 0;
        box-shadow: none;
        text-align: center;
      }
    }
  }

  .Badge--servicedApartments {
    position: absolute;
    top: 20px;
    left: 50px;

    @include lt(large) {
      top: 70px;
      left: 10px;
    }

    &.BelowMultilistingBadge {
      top: 60px;

      @include lt(large) {
        top: 120px;
      }

      @include lt(xsmall) {
        top: 170px;
      }
    }
  }
}

.ListingDetails .ListingDetails-MultilistingBookingFormContainer {
  @include lt(small) {
    padding: 0;
    height: 0;
    overflow: hidden;
    border: 0;
  }
}

.ListingDetailsPage-mobileCallOut {
  @include lt(large) {
    display: block;

    & > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 $spacing--small;
    }
  }

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-height: $height-mobileCallOut;
  display: none;
}

.ListingDetailsPage-mobileCallOut-info-legend {
  width: 100%;
  background: $palette--tint-2-grey;
  font-size: $font-size--g;
  font-weight: $font-weight--medium;
  color: $palette--grey;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListingDetailsPage-mobileCallOut-buttonOuter,
.ListingDetailsPage-mobileCallOut-info {
  padding: $spacing--small 0;
}

.ListingDetailsPage-mobileCallOut-info {
  line-height: 1;
}

.ListingDetailsPage-mobileCallOut-price {
  font-weight: $font-weight--bold;
  font-size: $font-size--d;
  margin-bottom: 6px;
}

.ListingDetailsPage-mobileCallOut-price-descriptor {
  font-size: $font-size--g;
  font-weight: normal;
}

.ListingDetailsPageMobileCallOut-trigger {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $palette--black;
}

.ListingDetailsPageMobileCallOut-previewDateButton {
  @include typo--body-3;
  align-items: center;
  background-color: transparent;
  border: none;
  display: inline-flex;
  font-weight: 400;

  &:first-of-type {
    padding-right: 2px;
  }

  &:nth-of-type(2) {
    padding-left: 2px;
  }
}

.ListingDetailsPageMobileCallOut-dateSeparator {
  color: $palette--black;
}

.ListingDetailsPageMobileCallOut-ctaPanel {
  justify-content: space-between;
}

.ListingDetailsPage-mobileCallOut-infoTenantServiceFee {
  font-size: $font-size--g;
  color: $palette--dark-gray-4;
  padding-right: $spacing--micro;
  text-decoration: underline;
}

.ListingDetails-shortlistButton {
  position: absolute;
  bottom: $spacing--small;
  right: $spacing--small;
}

.ListingDetails-shortlistButtonDesktop {
  height: 36px;
  margin-left: $spacing--small;
  background: $color-bg-white;
  border: 1px solid $color-bg;
  box-shadow: $box-shadow-floating-button;
  color: $palette--dark-gray-3;
}

.ListingDetails-shortlistButtonDesktop.isActive {
  background-color: $color-brand;
  color: white;
}

@keyframes coverImageAnimation {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-$animationTravelLength, 0, 0);
  }
}

.Multilisting-multilistingBadge {
  display: inline-block;
  margin: 10px 0 0 50px;
  position: absolute;
  top: 0;
  left: 0;

  @include lt(large) {
    margin: 0;
    display: block;
    padding-top: $height-app-header--mobile;

    .MultilistingBadge {
      width: 100%;
      border-radius: 0;
      color: #fff;
      background: transparent;
    }

    .MultilistingBadge-label {
      display: block;
      margin-right: 0;
    }
  }
}

.Multilisting-BookingForm {
  display: table;
  width: 100%;

  .FormHelpText {
    margin-top: 0;

    @include lt(small) {
      text-align: center;
    }
  }
}

.Multilisting-BookingForm-Icon-outer {
  display: table-cell;
  vertical-align: middle;
  width: 190px;

  @include lt(small) {
    display: none;
  }
}

.Multilisting-BookingForm-submit {
  margin-bottom: 15px;
}

.Multilisting-BookingForm-Icon {
  margin: 0 auto;
  width: 140px;
  height: 140px;
  border-radius: 100%;
  background-color: $color-bg;
  transition: opacity 2s;

  .icon {
    display: block;
    width: 100%;
    line-height: 140px;
    font-style: normal;
    text-align: center;
    font-size: 60px;
    color: $color-light;
    animation: fadeAndScaleIn 1s;

    &::before {
      display: inline-block;
      height: 60px;
      width: 60px;
    }

    &--calendar::before {
      @include icon($icon--calendar);
      width: 56px;
    }

    &--tick::before {
      padding-top: 5px;
      @include icon($icon--tick);
    }

    &--block::before {
      @include icon($icon--block);
    }
  }

  @include lt(small) {
    display: none;
  }
}

.Multilisting-BookingForm-content {
  display: table-cell;
  vertical-align: middle;

  @include lt(small) {
    margin-left: 0;
  }
}

.Multilisting-BookingForm-content-title {
  font-size: 20px;
  margin: 0 0 25px 0;
}

.Multilisting-BookingForm-content-subtitle {
  font-size: $font-size--f;
  font-weight: normal;
}

.Multilisting-BookingForm-content-inputs {
  margin-top: $spacing--base * 3;

  > label {
    position: relative;
    display: inline-block;

    @include lt(small) {
      width: 100%;
      margin-bottom: 15px;
    }

    span:first-child {
      position: absolute;
      top: 0;
      left: 20px;
      line-height: 38px;

      @media (max-width: 480px) {
        display: none;
      }
    }
  }

  .DateTextInput {
    text-align: right;
    margin-bottom: 15px;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  .DateTextInput,
  .btn {
    min-width: 12em;
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;

    @include lt(small) {
      width: 100%;
      min-width: 0;
    }
  }

  .btn.btn-secondary {
    line-height: 34px;
  }
}

.Multilisting-BookingForm-Message {
  text-align: left;
  margin-top: 1rem;
  font-size: $font-size--f;
  min-height: 18px;

  &--error {
    color: $color-error;
  }
}

.ListingDetails-indicatorWrapper {
  margin-top: $spacing--minor;

  @include gt(large) {
    margin-top: 0;
  }

  @include gt(medium) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.ListingDetails-landlordVerified,
.ListingDetails-studentOnly {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $font-size--f;
  font-weight: $font-weight--bold;

  &-image {
    margin-right: 12px;
  }

  &:first-of-type {
    @include gt(medium) {
      margin-right: $spacing--medium;
    }
  }
}

.ListingDetails-mobileButtonGroup {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  bottom: $spacing--small - 1;
  left: 0;
  height: $button-2019-default;
  width: calc(100% - #{$button-2019-default * 2} - #{$spacing--small * 3});
  z-index: 1;

  .ListingDetails-viewGalleryButton {
    margin-left: $spacing--small;
  }

  .ListingDetails-floorPlanButton--noLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $button-2019-default;
    height: $button-2019-default;
    margin-left: $spacing--small;
    padding: 0;
  }
}

.ListingDetails-houseRules p {
  color: $color-2019--font-secondary;
}

.ListingDetails article .ListingDetails-houseRules p {
  line-height: $line-height--small;
}

.ListingDetails-houseRulesContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: $spacing--medium;

  @include lt(small) {
    flex-direction: column;
  }
}

.ListingDetails-houseRule {
  display: flex;
  width: auto;
  max-width: 50%;

  &-icon {
    width: $spacing--medium;
    height: $spacing--medium;
  }
  &-text {
    margin-left: $spacing--base * 2;
  }
  &-conditionText {
    font-size: $font-size--g;
  }
  &-heading {
    color: $color-2019--font-primary;
  }
  &:not(:first-child) {
    margin-left: $spacing--medium;
  }

  @include lt(small) {
    max-width: 100%;

    &:not(:first-child) {
      margin-left: 0;
      margin-top: $spacing--medium;
    }
  }
}

.ListingDetails-minimumStay {
  margin: -$spacing--medium 0 $spacing--medium 0;
  font-family: $primary-font-stack;
  color: $color-2019--font-secondary;
  font-weight: $font-weight--medium;

  @include lt(small) {
    margin-top: -$spacing--small;
  }
}

.ListingDetails-about-subtitle {
  margin-bottom: $spacing--small;
}

.ListingDetails-readMoreToggle {
  margin-top: $spacing--small;
  display: block;
  text-decoration: underline;
  font-weight: $font-weight--medium;

  &:hover {
    color: #000000;
  }
}

.ListingDetails-additional-info {
  margin-top: $spacing--medium;

  h4 {
    margin-bottom: $spacing--small;
  }
}

.ListingDetails-virtualTour {
  // https://support.matterport.com/hc/en-us/articles/115004551427-Place-and-Resize-your-Embed#make-3d-showcase-responsive-0-3
  .matterport-showcase iframe {
    width: 853px;
    height: 480px;
    min-height: 240px;
    margin: 16px 0;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .matterport-showcase iframe {
      min-height: 360px;
    }
  }

  @media screen and (min-width: 992px) {
    .matterport-showcase iframe {
      height: 600px;
      max-width: 1068px;
      margin: 0 auto 15px;
    }
  }

  .matterport-showcase {
    text-align: center;
  }
}

/** Selector with 2 classes is needed here to override the Button
 default stylings which also use 2 classes for selectors. */
.ListingDetails-shareButton.ShareButton {
  background: $color-bg-white;
  box-shadow: $box-shadow-floating-button;
  color: $color-2019--font-secondary;
  padding: 0;
  width: $button-2019-default;
  height: $button-2019-default;

  &:hover {
    background: $color-bg-white;
  }

  @include gt(large) {
    position: initial;
    margin-left: $spacing--small;
    padding: 0 $spacing--small;
    width: auto;
  }

  .ShareButton-label {
    display: none;

    @include gt(large) {
      display: inline;
    }
  }

  .ShareButton-icon {
    margin-right: 0;

    @include gt(large) {
      margin-right: $spacing--base;
    }
  }
}

.ListingDetails-image {
  .popper-reference {
    position: absolute;
    right: 66px;
    bottom: 12px;
    width: 36px;
    height: 36px;

    @include gt(large) {
      position: initial;
    }
  }
}

.ListingDetails-availability {
  align-items: center;
  display: flex;
  margin: $spacing--minor 0;
  flex-wrap: wrap;

  &_duration {
    @include lt(medium) {
      margin-bottom: 20px;
    }
  }

  &_date,
  &_duration {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &::before {
      background-color: $palette--tint-2-grey;
      background-size: $spacing--minor $spacing--minor;
      border-radius: 50%;
      height: $spacing--medium;
      width: $spacing--medium;
    }
  }

  &_date::before {
    @include svgIcon($icon--schedule);
  }

  &_duration::before {
    @include svgIcon($icon--clock);
  }

  .stats {
    &Wrapper {
      margin-left: $spacing--small - 2px;
      margin-right: $spacing--minor;

      @include gt(small) {
        margin-right: $spacing--medium;
      }
    }

    &Label {
      color: $palette--dark-grey;
      font-size: $font-size--f;
      font-weight: $font-weight--medium;
    }

    &Text {
      color: $palette--black;
      font-size: $font-size--e;
      font-weight: $font-weight--bold;
    }
  }
}

.ListingDetails-minBookingDurationPermitId {
  &--label {
    font-weight: $font-weight--bold;
  }

  &--value {
    font-weight: $font-weight--medium;
  }
}

.ListingDetails-certifications
  .ListingDetails-certificationsContainer
  > div:not(:last-of-type) {
  padding-bottom: $spacing--small;
}

.ListingDetails-certifications .ListingDetails-certificationsContainer > div {
  margin-top: $spacing--small;
}

.ListingDetails-certifications
  .ListingDetails-certificationsContainer
  > div
  > h4 {
  margin-bottom: $spacing--base * 2;
}

.ListingDetails-map {
  .leaflet-pane,
  .leaflet-bottom {
    z-index: 0;
  }
}
