.AuthComponent-Form-PasswordForm {
  margin-top: $spacing--small;
  animation: passwordFormFadeInDown $login-transition-timing
    $login-transition-function;
}

.PasswordForm-additionalActionsContainer {
  margin-top: 15px;
  display: grid;
  align-items: center;
  grid-auto-rows: 40px;

  @include gt(large) {
    grid-template-columns: 1fr max-content max-content;
    gap: 30px;
  }
}

@keyframes passwordFormFadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
