.fieldset {
  min-width: 0;
  border: 0;
  margin: $spacing--medium 0;
  padding: 0;
}

.input,
.textarea {
  font-family: $primary-font-stack;
  @include ms-font-size(minus1);
  border: $border-input;
  border-radius: $border-radius--small;
  padding: ($spacing--small - $border-width--medium) $spacing--small;
  line-height: $line-height--micro;
  transition: border-color ease 100ms;

  &:hover {
    border-color: darken($color-input, 10);
  }

  &:focus {
    outline: 0;
    border-color: $color-brand;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color-text-light-gray;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color-text-light-gray;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color-text-light-gray;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-text-light-gray;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-text-light-gray;
  }
}

.textarea {
  width: 100%;
  min-height: 240px;
  line-height: $line-height--medium;

  &--small {
    min-height: 120px;
  }
}

.asterisk {
  color: $palette--indian-red-dark;
  padding-left: 5px;
}
