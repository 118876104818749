.DropdownInput-container {
  position: relative;
  width: 100%;
}

.DropdownInput-select {
  outline: none;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  background: $palette--tint-1-grey;
  border: 1px solid $palette--tint-4-grey;
  box-sizing: border-box;
  border-radius: $spacing--base;
  height: 46px;
  width: 100%;
  padding: 0 $spacing--base * 4;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: $palette--black;
  cursor: pointer;
}

.DropdownInput-select:hover {
  border: 1px solid $palette--tint-3-grey;
  background: $palette--tint-2-grey;
}

.DropdownInput-select:focus {
  background: $palette--pale-purple;
  border: 1px solid $palette--purple;
}

.DropdownInput-select--empty {
  color: $palette--silver;
}

.DropdownInput-select--error {
  background: $palette--pale-red;
  border: 1px solid $palette--indian-red;
  color: $palette--indian-red;
}

.DropdownInput-select--error.DropdownInput-select--empty {
  color: rgba($palette--indian-red, 0.3);
}

.DropdownInput-select--no-color,
.DropdownInput-select.DropdownInput-select--no-color:focus {
  color: transparent;
}

.DropdownInput-custom-selected-value-container {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.DropdownInput-item {
  color: $palette--black;
}

.DropdownInput-icon {
  pointer-events: none;
  position: absolute;
  right: $spacing--base * 3;
  top: 50%;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropdownInput-triangle {
  display: inherit;
  margin-top: 2px;
}

.WindowsOS {
  .DropdownInput-select:focus {
    background: transparent;
    color: $palette--black;
  }

  .DropdownInput-select:hover {
    color: $palette--black;
  }

  .DropdownInput-select:focus:hover {
    background: transparent;
    color: $palette--black;
  }
}

@-moz-document url-prefix() {
  .DropdownInput-select--empty,
  .DropdownInput-select--empty:hover,
  .DropdownInput-select--empty:focus,
  .DropdownInput-select--empty:focus:hover {
    color: $palette--black !important;
  }
  .DropdownInput-select--empty:focus,
  .DropdownInput-select--empty:focus:hover {
    background: transparent !important;
  }
}
