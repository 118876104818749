.Checkbox {
  &-label {
    display: table;
    vertical-align: middle;
    cursor: pointer;
  }

  &-checkbox {
    margin: 0;
    vertical-align: middle;
  }

  &-textContainer {
    display: table-cell;
    padding-left: 10px;
    font-size: 1em;
    vertical-align: middle;
  }

  &-helpText {
    display: block;
    color: $color-light;
  }

  &-labelText {
    display: block;
  }

  &-checkboxContainer {
    display: table-cell;
  }

  &--small {
    font-size: $font-size--f;
    line-height: $line-height--small;
  }
}
