@mixin icon($icon) {
  font-family: "budicon";
  content: $icon;
}

@mixin svgIcon($icon) {
  content: "";
  background-image: $icon;
  background-position: center;
  background-repeat: no-repeat;
}

$icon--request: "\e8b7";
$icon--out-link: "\eb06";
$icon--apartments: "\e905";
$icon--article: "\e840";
$icon--block: "\eac4";
$icon--briefcase: "\e853";
$icon--building: "\e905";
$icon--calendar: "\e99d";
$icon--camera: "\e9df";
$icon--chevron-left: "\eafa";
$icon--chevron-right: "\eb14";
$icon--circled-tick-large: "\eadb";
$icon--circled-tick: "\eada";
$icon--circled-user: "\ea0e";
$icon--circled-close: "\ead7";
$icon--circled-minus: "\ead8";
$icon--circled-plus: "\ead9";
$icon--clock-left: "\e9a0";
$icon--close: "\eae3";
$icon--document: "\e886";
$icon--dog: "\e813";
$icon--door: "\e924";
$icon--download: "\ea3c";
$icon--enlarge: "\eae9";
$icon--envelope: "\e81a";
$icon--euro: "\e8b8";
$icon--eye: "\eb2b";
$icon--facebook: "\ea6d";
$icon--group: "\ea12";
$icon--heart: "\eaf2";
$icon--id-card: "\e892";
$icon--instagram: "\ea6f";
$icon--linkedin: "\ea70";
$icon--key: "\ea1f";
$icon--life-preserver: "\eaf3";
$icon--lined-paper: "\e886";
$icon--location-check: "\e9b4";
$icon--location-pin: "\e9b2";
$icon--map: "\e9b9";
$icon--login: "\eb00";
$icon--logout: "\eb01";
$icon--menu: "\eaf1";
$icon--new-user: "\e9fd";
$icon--no-smoking: "\e917";
$icon--office: "\e904";
$icon--padlock: "\ea20";
$icon--plus: "\eb0a";
$icon--minus: "\eb03";
$icon--plus-alt: "\eb41";
$icon--refresh: "\eb0d";
$icon--sad: "\ea8d";
$icon--search: "\eb19";
$icon--settings: "\e976";
$icon--star: "\eb20";
$icon--telephone: "\e82f";
$icon--tick: "\eb23";
$icon--trash: "\e8f0";
$icon--twitter: "\ea79";
$icon--upwards-chart: "\ea4d";
$icon--user: "\e9f9";
$icon--user-cross: "\ea0b";
$icon--verified-user: "\ea0a";
$icon--wallet-open: "\e8e1";
$icon--wallet: "\e8e0";
$icon--warning: "\ea56";
$icon--list: "\eabf";
$icon--house: "\e901";
$icon--paper-plane: "\e82e";
$icon--arrow-left: "\eb47";
$icon--location-compass: "\e9b0";
$icon--bookmark: "\e84f";

$icon--apartment-size: url("/public/images/listings-page/apartment-size.svg");
$icon--clock: url("/public/images/listings-page/clock.svg");
$icon--bed: url("/public/images/listings-page/bed.svg");
$icon--double-bed: url("/public/images/listings-page/double-bed.svg");
$icon--bunk-bed: url("/public/images/listings-page/bunk-bed.svg");
$icon--single-bed: url("/public/images/listings-page/single-bed.svg");
$icon--couch-bed: url("/public/images/listings-page/couch-bed.svg");
$icon--other-bed: url("/public/images/listings-page/other-bed.svg");
$icon--guests: url("/public/images/listings-page/guests.svg");
$icon--room: url("/public/images/listings-page/room.svg");
$icon--schedule: url("/public/images/listings-page/schedule.svg");
$icon--wifi: url("/public/images/listings-page/wifi.svg");
