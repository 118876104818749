.CardButton {
  display: block;
  width: 100%;
  padding: $spacing--small;
  border: 0;
  margin: 0;
  align-self: center;
  line-height: $line-height--medium;
  font-family: $primary-font-stack;
  @include ms-font-size(minus1);
  font-weight: $font-weight--bold;
  text-align: center;
  background-color: $color-bg-white;
  color: $color-text-gray;
  border-top: 1px solid $color-input;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;
  border-radius: 0 0 $border-radius--medium $border-radius--medium;

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus {
    color: darken($color-text-gray, 10);
  }
}

.CardButton + .CardButton {
  border-left: 1px solid $color-input;
}

// Negative button styles
.CardButton--negative {
  color: $color-danger;

  &:hover,
  &:focus {
    color: darken($color-danger, 10);
  }

  &.CardButton--primary {
    background-color: $color-danger;
    color: $color-text-light;
    border-top-color: $color-danger;

    &:hover,
    &:focus {
      background-color: darken($color-danger, 5);
    }
  }
}

.CardButton + .CardButton--negative.CardButton--primary {
  border-left-color: $color-danger;
}

// Positive button styles
.CardButton--positive {
  color: $color-success;

  &:hover,
  &:focus {
    color: $palette--darker-green;
  }

  &.CardButton--primary {
    background-color: $color-success;
    color: $color-text-light;
    border-top-color: $color-success;

    &:hover,
    &:focus {
      background-color: $palette--dark-green;
    }
  }
}

.CardButton + .CardButton--positive.CardButton--primary {
  border-left-color: $color-success;
}

// Disabled/loading button styles
.CardButton--disabled,
.CardButton--loading {
  cursor: not-allowed;
  color: $color-light;

  &:hover,
  &:focus {
    color: $color-light;
  }

  &.CardButton--primary {
    color: $color-text-light;
    background-color: $color-light;
    border-top: 1px solid $color-light;

    &:hover,
    &:focus {
      color: $color-text-light;
    }

    &.CardButton--positive:hover,
    &.CardButton--positive:focus,
    &.CardButton--negative:hover,
    &.CardButton--negative:focus {
      background-color: $color-light;
    }
  }
}

.CardButton + .CardButton--primary.CardButton--disabled,
.CardButton + .CardButton--primary.CardButton--loading {
  border-left-color: $color-light;
}
