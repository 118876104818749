%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --scrollbarWidth: 0px;
}

html {
  position: relative;
  height: 100%;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: white;
}

.wrapper {
  max-width: 1120px;
  margin: 0 auto;
  text-align: left;
}

fieldset {
  border: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img {
  // Visually hides alt text while image is loading / not present.
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

ul {
  list-style-type: none;
}

figure {
  margin: 0;
}

#main,
.view {
  position: relative;
  height: 100%;
}

.error-message {
  color: $color-error;
  font-size: 12px;
}

.error {
  color: $color-error;
}

.warning {
  color: $color-warning;
}

a.btn {
  display: inline-block;
  vertical-align: middle;
}

.btn,
a.btn {
  font-family: $primary-font-stack;
  @include ms-font-size(minus1);
  height: 36px;
  padding: 0 20px;
  border: none;
  border-radius: $border-radius--large * 2;
  line-height: 36px;

  &:focus {
    outline: 0;
  }

  &-action {
    font-weight: bold;
    color: #fff;
    background-color: $color-2019--primary;

    &:hover {
      background-color: darken($color-2019--primary, 3%);
    }
  }

  &-full {
    display: block;
    width: 100%;
    text-align: center;
  }

  &-danger {
    background: $color-danger;
    color: white;
  }

  &-link {
    font-family: $primary-font-stack;
    @include ms-font-size(minus1);

    line-height: normal;
    height: auto;
    background: none;
    padding: 0;
    border: 0;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
    &--danger {
      color: $color-danger;
    }
    &--full-width {
      width: 100%;
    }

    &[disabled],
    &:disabled {
      background: none !important;
      color: $color-light !important;
    }
  }

  &-warning {
    background: $color-warning;
    color: white;
    &:hover {
      background: darken($color-warning, 10%);
    }
  }

  &-secondary {
    background: none;
    color: $color-text;
    border: 2px solid #bdc3d5;
    font-weight: bold;
    line-height: 34px; // Adjustment for 2px border in combination with box-sizing: border-box
  }

  &-secondaryAlt {
    border: none;
    background: rgba(255, 255, 255, 0.25);
    color: rgba(255, 255, 255, 0.75);
    font-weight: normal;
  }

  &-delete {
    background: none;
    border: 2px solid $color-error;
    color: $color-error;
  }

  &-facebook {
    background-color: $color-facebook;
    color: #fff;

    &:hover {
      background-color: darken($color-facebook, 3%);
    }
  }

  &-brand {
    background-color: $color-brand;
    color: #fff;

    &:hover {
      background-color: darken($color-brand, 3%);
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    color: #fff;
    background-color: $color-light;
  }

  &-disabled {
    cursor: not-allowed;
    color: #fff;
    background-color: $color-light;
    pointer-events: none;
  }

  &-jumbo {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    border-radius: 100px;
    padding: 0 40px;
  }
}

.btn-action.btn-loading {
  color: rgba(white, 0);
  background-image: $spinner-action;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.txt-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt-light {
  color: $color-light;
}

.price {
  display: inline-block;
  padding: 4.5px 13px 5.5px;
  border-radius: 13px;
  line-height: 16px;
  color: #fff;
  background: $color-brand-dark;
}

.view .Select-arrow {
  border-color: #000 transparent transparent;
  border-style: solid;
  border-width: 4px 3px 3px;
}

.full {
  &-width {
    width: 100%;
  }
  &-height {
    height: 100%;
  }
}
