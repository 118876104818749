.CustomPhoneNumberInput,
.CustomPhoneNumberInput.Input {
  position: relative;
  display: flex;
  padding-left: 0;

  @include lt(xsmall) {
    border: none;
    height: inherit;
    flex-direction: column;
    padding: 0;
  }
}

.ErrorIndicator--invalid .CustomPhoneNumberInput-outer {
  border-radius: $border-radius--medium;
}

.CustomPhoneNumberInput-dialCode-container {
  display: flex;
  @include gt(small) {
    border: none;
    height: 100%;
    width: auto;
    padding: 0;
  }
}

.CustomPhoneNumberInput-dialCode-container.Input {
  @include lt(xsmall) {
    padding-left: 0;
  }
}

.CustomPhoneNumberInput-phoneNumber-container {
  margin-top: $spacing--micro;
  padding: 0;
  flex: 1 1 0%;
  @include gt(small) {
    border: none;
    height: 100%;
    padding: 0;
    margin-top: 0;
  }
}

.CustomPhoneNumberInput-country {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: $spacing--small;
}

.CustomPhoneNumberInput-country-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.CustomPhoneNumberInput-country-flag {
  margin: 0;
  min-width: 45px;
  white-space: nowrap;

  img {
    max-width: 27px;
    max-height: 23px;
    display: inline-block;
    vertical-align: middle;
  }

  &:after {
    content: "";
    margin-left: 12px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 5px solid #000;
    display: inline-block;
    vertical-align: middle;
  }
}

.CustomPhoneNumberInput-textInput {
  border: 0;
  height: 41px;
}

.CustomPhoneNumberInput-dialCode-icon {
  align-self: center;
  padding-left: $spacing--small;
  &:before {
    content: "+";
  }
}

.CustomPhoneNumberInput-dialCode {
  max-width: 60px;
  padding: 0;
  border-right: 2px solid $color-divider;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  @include lt(xsmall) {
    border-right: none;
  }
}

.CustomPhoneNumberInput-phoneNumber {
  min-width: 0;
}

.CustomPhoneNumberInput-dialCode-locked {
  padding-right: $spacing--micro;
  &:before {
    content: "+";
  }
}

.CustomPhoneNumberInput-isVerified {
  color: $palette--green;
  margin-left: $spacing--small;
  line-height: 1.6;
  @include lt(xsmall) {
    margin-left: 0;
  }
}

.CustomPhoneNumberInput-isVerified-tickMark {
  vertical-align: middle;
  margin-right: 5px;
  &:before {
    @include icon($icon--circled-tick);
  }
}

.CustomPhoneNumberInput-isVerified-text {
  font-family: helvetica neue;
  font-size: 12px;
  font-weight: 500;
}
