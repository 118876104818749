.PhoneNumberInput {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: $spacing--base;
  height: 46px;
  font-family: $primary-font-stack;
  font-size: $font-size--f;
  background: $palette--tint-1-grey;
  border: 1px solid $palette--tint-4-grey;
}

.PhoneNumberInput:hover {
  background: $palette--tint-2-grey;
  border: 1px solid $palette--tint-4-grey;

  .PhoneNumberInput-phoneInput {
    background: $palette--tint-2-grey;
  }
}

.PhoneNumberInput:focus-within {
  background: $palette--pale-purple;
  border: 1px solid $palette--purple;

  .PhoneNumberInput-phoneInput {
    background: $palette--pale-purple;
    outline: none;
  }
}

.PhoneNumberInput--error {
  background: $palette--pale-red;
  border: 1px solid $palette--indian-red;

  &:hover,
  &:focus,
  &:focus-within {
    background: $palette--pale-red;
    border: 1px solid $palette--indian-red;

    .PhoneNumberInput-phoneInput {
      background: $palette--pale-red;
    }
  }
}

.PhoneNumberInput-phoneInput {
  border: none;
  font-family: inherit;
  font-size: inherit;
  background: $palette--tint-1-grey;
  width: 100%;
  border-top-right-radius: $spacing--base;
  border-bottom-right-radius: $spacing--base;
  overflow: hidden;
}

.PhoneNumberInput-phoneInput--error {
  background: $palette--pale-red;
}

.PhoneNumberInput-phoneInput::placeholder {
  color: #b5bec5;
}

.PhoneNumberInput-phoneInput--focused::placeholder {
  color: rgba(#732dd9, 0.35);
}

.PhoneNumberInput-phoneInput--error::placeholder {
  color: rgba(#eb5757, 0.35);
}

.PhoneNumberInput-countrySelect {
  cursor: pointer;
  opacity: 0;
  width: $spacing--base * 10;
  margin-left: $spacing--micro;
  z-index: 1;
  height: $spacing--medium;
  font-family: $input-font-stack;
}

.PhoneNumberInput-flag {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: $spacing--base * 10;
  min-height: $spacing--medium;
  background: $palette--pure-white;
  border: 1px solid $palette--tint-3-grey;
  box-sizing: border-box;
  box-shadow: $box-shadow-floating-select;
  border-radius: $spacing--medium;
  margin: 0 $spacing--micro 0 $spacing--base * -10;
}

select:focus ~ .PhoneNumberInput-flag {
  border: 1px solid #732dd9;
}

.PhoneNumberInput-flag:after {
  content: "";
  margin-left: $spacing--micro;
  border-left: $spacing--base solid transparent;
  border-right: $spacing--base solid transparent;
  border-top: $spacing--base solid $palette--dark-grey;
  display: inline-block;
  vertical-align: middle;
}

.PhoneNumberInput-flag--circular {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 17px;
  height: 17px;
  overflow: hidden;
  border-radius: 50%;
}

.PhoneNumberInput-flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PhoneNumberInput-dialCode {
  color: black;
  margin-right: $spacing--micro * 0.5;
}
