// small, medium, large, xlarge
// To Fix: There should be a gap of 1px difference between gt and lt.

@mixin lt($point) {
  @if $point == xlarge {
    @media (max-width: 99999px) {
      @content;
    }
  } @else if $point == large {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == small {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point == xsmall {
    @media (max-width: 480px) {
      @content;
    }
  } @else {
    @media (max-width: $point) {
      @content;
    }
  }
}

@mixin gt($point) {
  @if $point == xxlarge {
    @media (min-width: 1920px) {
      @content;
    }
  } @else if $point == widescreen {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $point == xlarge {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: 993px) {
      @content;
    }
  } @else if $point == medium {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == small {
    @media (min-width: 480px) {
      @content;
    }
  } @else if $point == xsmall {
    @media (min-width: 374px) {
      @content;
    }
  } @else if $point == xxsmall {
    @media (min-width: 1px) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}

@mixin eq($point) {
  @if $point == xlarge {
    @media (min-width: 1201px) {
      @content;
    }
  }
  @if $point == large {
    @media (min-width: 993px) and (max-width: 1199px) {
      @content;
    }
  }
  @if $point == medium {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }
  @if $point == small {
    @media (max-width: 766px) {
      @content;
    }
  }
}

.hide-xlarge {
  @include eq(xlarge) {
    display: none;
  }
}

.hide-large {
  @include eq(large) {
    display: none;
  }
}

.hide-medium {
  @include eq(medium) {
    display: none;
  }
}

.hide-small {
  @include eq(small) {
    display: none;
  }
}

.hide-lt-medium {
  @include lt(medium) {
    display: none;
  }
}

.hide-gt-large {
  @include gt(large) {
    display: none;
  }
}
