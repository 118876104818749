@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.InputTooltip-container {
  display: inline-block;
}

.InputTooltip-iconContainer {
  background: none;
  border: none;
  cursor: pointer;
}

.InputTooltip-closeButton {
  margin-top: 25px;
}

.InputTooltip-text a {
  color: inherit;
  text-decoration: underline;
}
