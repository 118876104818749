@use "sass:math";

.MarkerIcon {
  position: relative;
  line-height: 28px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: white;
  padding: 0 10px;
  min-width: max-content;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 30px;
    border-radius: 15px;
    background: $palette--wunder-brand-purple;
  }

  &::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 25px;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: $palette--wunder-brand-purple transparent transparent;
  }

  &.is-highlighted {
    // z-index is set on the style attribute of the marker by leaflet (therefore
    // we need to use !important). Setting it to a high value here to ensure
    // that the marker is completely visible when being highlighted by hovering
    // its corresponding listing tile:
    z-index: 1000 !important;

    &::after {
      border-top-color: $color-action;
      transition: border 150ms linear;
    }

    &::before {
      background: $color-action;
      transition: background-color 150ms linear;
    }
  }
}

.RefugeeListingMarkerIcon {
  transition: 150ms linear;
  background: url("/public/images/listings-page/refugee-listing-marker.svg")
    no-repeat left;
  background-size: cover;

  &.is-highlighted {
    z-index: 1000 !important;
  }
  &.is-selected {
    background-image: url("/public/images/listings-page/refugee-listing-marker-active.svg");
    z-index: 1000 !important;
  }
}

.ListingMarker {
  position: relative;
  width: 260px;
  height: 300px;
  font: $primary-font-stack;

  .ImageGallery {
    width: 100%;
    height: 180px;

    .ImageGallery-container {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      @media (orientation: landscape) {
        background-size: 100% auto;
      }
    }

    .ImageGallery-navButton {
      width: 44px;
      z-index: 3;

      img {
        width: 18px;
      }
    }
  }

  h3 a {
    font-weight: normal;
    color: $color-text;
  }

  .Listing-link {
    padding: 200px $spacing--small $spacing--small;
    font-size: $font-size--f;
    line-height: math.div($line-height--jumbo, 3);
  }

  .Listing-linkText {
    margin-top: $spacing--small;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 3em;
    visibility: visible;
  }

  .ListingMarker-details {
    position: absolute;
    left: $spacing--small;
    right: $spacing--small;
    bottom: $spacing--small;
    white-space: nowrap;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ListingPrice {
    position: absolute;
    top: 170px;
    left: 20px;
  }
}

.ListingMarker-rent {
  font-size: $font-size--e;
  margin: $spacing--micro 0 $spacing--small $spacing--small;
}

.ListingMarker-listingPrice {
  font-size: $font-size--g;
  font-weight: normal;
  color: $color-2019--font-secondary;
  margin-left: math.div($spacing--micro, 3);
}

.leaflet-popup-close-button {
  position: absolute !important;
  top: 5px !important;
  right: 7.5px !important;
  border: none !important;
  border-radius: 100% !important;
  font-family: $primary-font-stack !important;
  font-size: 18px !important;
  color: white !important;
  background: rgba($color-2019--font-primary, 0.25) !important;
  outline: none !important;
  padding: 0 !important;
  text-align: center !important;
  cursor: pointer !important;

  height: $button-2019--small !important;
  width: $button-2019--small !important;
  line-height: 18px !important;
  z-index: 10 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px !important;
  padding-left: 1px !important;

  &:hover {
    color: $palette--pure-white !important;
  }
}

.ListingMarker .ListMarker-shortlistBtn.ShortlistButton.noLabel {
  width: $button-2019--small;
  height: $button-2019--small;
  position: absolute;
  top: 5px;
  left: 7.5px;
  box-shadow: none;
  border: 1px solid $palette--tint-3-grey;
  z-index: 10;
}

.ListingMarker .ListMarker-shortlistBtn.ShortlistButton svg {
  transform: scale(0.8);
}

.ListingMarker .ListingDetailsBrief {
  font-size: $font-size--g;
}

.ListingMarker .ListingDetailsBrief-area,
.ListingMarker .ListingDetailsBrief-beds,
.ListingMarker .ListingDetailsBrief-rooms {
  margin-right: 17px;
}

.RefugeeListingPopup {
  box-shadow:
    0px 82px 23px 0px rgba(0, 0, 0, 0),
    0px 52px 21px 0px rgba(0, 0, 0, 0.01),
    0px 29px 18px 0px rgba(0, 0, 0, 0.05),
    0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1);
  .leaflet-popup-content-wrapper {
    border-radius: 10px;
  }

  .ListingMarker .ImageGallery .ImageGallery-container {
    border-radius: 10px 10px 0 0;
  }

  .leaflet-popup-tip {
    display: none;
  }
}
