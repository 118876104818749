@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appearFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeAndScaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

$login-transition-function: cubic-bezier(0.25, 0.1, 0.25, 1);
$login-transition-timing: 0.3s;
$login-transition: all $login-transition-timing $login-transition-function;
