@import "styles/fonts";
@import "styles/variables";
@import "styles/mixins";
@import "styles/icons";
@import "styles/general";
@import "styles/z-index";
@import "styles/media";
@import "styles/typography";
@import "styles/animation";
@import "styles/form";
@import "styles/table-grid";
@import "styles/pages";
@import "styles/grid";

@import "components/_Legacy/Popover/Popover";

@import "components/SimpleModal/SimpleModal";
@import "components/FormCardModal/FormCardModal";
@import "components/MapContainer/MapContainer";
@import "components/Modal/Modal";
@import "components/Listing/Listing";
@import "components/ListingDetailsBrief/ListingDetailsBrief";
@import "components/CoverImage/CoverImage";
@import "components/DateWidget/DateWidget";
@import "components/DateMonthView/DateMonthView";
@import "components/Input/Input";
@import "components/Checkbox/Checkbox";
@import "components/StyledCheckbox/StyledCheckbox";
@import "components/Textarea/Textarea";
@import "components/DateTextInput/DateTextInput";
@import "components/Footer/Footer";
@import "components/ListingMarker/ListingMarker";
@import "components/ForgotPasswordModal/ForgotPasswordModal";
@import "components/ListingPrice/ListingPrice";
@import "components/ErrorMessage/ErrorMessage";
@import "components/ErrorIndicator/ErrorIndicator";
@import "components/Select/Select";
@import "components/SearchResults/SearchResults";
@import "components/SvgSpinner/SvgSpinner";
@import "components/CustomPhoneNumberInput/CustomPhoneNumberInput";
@import "components/BankAccount/BankAccount";
@import "components/BankAccountForm/BankAccountForm";
@import "components/WunderflatsStats/WunderflatsStats";
@import "components/FeaturedCompanies/FeaturedCompanies";
@import "components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
@import "components/PhoneNumber/PhoneNumber";
@import "components/EmailAddress/EmailAddress";
@import "components/Switch/Switch";
@import "components/MultilistingBadge/MultilistingBadge";
@import "components/StickyHeader/StickyHeader";
@import "components/FullWidthCard/FullWidthCard";
@import "components/ExtraAreaButton/ExtraAreaButton";
@import "components/Shortlist/ShortlistButton";
@import "components/ListingItem/ListingItem";
@import "components/Popover/Popover";
@import "components/FormElements/VerticalRadio/VerticalRadio";
@import "components/FormElements/DropdownInput/DropdownInput";
@import "components/FormElements/InputText/InputText";
@import "components/FormElements/InputTextArea/InputTextArea";
@import "components/FormElements/PhoneNumberInput/PhoneNumberInput";
@import "components/AuthComponent/AuthComponent";
@import "components/FormElements/InputWrapper/InputWrapper";
@import "components/FormElements/InputWithButton/InputWithButton";
@import "components/FormElements/InputPassword/InputPassword";
@import "components/FormElements/VerticalInputContainer/VerticalInputContainer.scss";
@import "components/Drawer/Drawer.scss";

@import "spages/AuthContainer/FlashMessage/FlashMessage";
@import "spages/spa/Pages/ListingDetailsPage/ListingDetailsPage.scss"; // remove from here when new availability page is finished
@import "spages/error/ErrorPage/ErrorPage.scss";

@import "styles/helpers";

.Select-control,
.Select--single .Select-control {
  border: 0;
  height: auto;
  .Select-value,
  &.is-focused .Select-value {
    color: $color-text;
  }
}

.no-scroll {
  overflow: hidden;
}

.root {
  min-height: 100vh;
  height: 100%;
}
