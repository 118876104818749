.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.marginBottom {
  &--single {
    margin-bottom: $spacing--medium;
  }

  &--double {
    margin-bottom: $spacing--large;
  }

  &--half {
    margin-bottom: $spacing--small;
  }

  &--none {
    margin-bottom: 0;
  }
}

.hide-on-mobile {
  @include lt(medium) {
    display: none;
  }
}

.disable-scroll {
  overflow: hidden;

  @include lt(small) {
    position: fixed;
  }
}
