@font-face {
  font-family: "budicon";
  src: url("/public/fonts/budicon.eot?42886572");
  src:
    url("/public/fonts/budicon.eot?42886572#iefix") format("embedded-opentype"),
    url("/public/fonts/budicon.woff?42886572") format("woff"),
    url("/public/fonts/budicon.ttf?42886572") format("truetype"),
    url("/public/fonts/budicon.svg?42886572#budicon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin buildFontFace($fontFamily, $fontWeight) {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: $fontWeight;
  src: url("/public/fonts/#{$fontFamily}-#{$fontWeight}.eot"); /* IE9 Compat Modes */
  src:
    local(""),
    url("/public/fonts/#{$fontFamily}-#{$fontWeight}.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/public/fonts/#{$fontFamily}-#{$fontWeight}.woff2"),
    /* Super Modern Browsers */
      url("/public/fonts/#{$fontFamily}-#{$fontWeight}.woff") format("woff"),
    /* Modern Browsers */ url("/public/fonts/#{$fontFamily}-#{$fontWeight}.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/public/fonts/#{$fontFamily}-#{$fontWeight}.svg##{fontFamily}")
      format("svg"); /* Legacy iOS */
  font-display: swap;
}

/* Poppins */
@font-face {
  @include buildFontFace("poppins", 300);
}

@font-face {
  @include buildFontFace("poppins", 400);
}

@font-face {
  @include buildFontFace("poppins", 500);
}

@font-face {
  @include buildFontFace("poppins", 600);
}

/* Roboto */
@font-face {
  @include buildFontFace("roboto", 300);
}

@font-face {
  @include buildFontFace("roboto", 400);
}

@font-face {
  @include buildFontFace("roboto", 500);
}
