.MultilistingUnavailableCard {
  animation: appear 0.2s ease-out;
  opacity: 1;
  transition: opacity 0.2s ease-out;

  &--hide {
    opacity: 0;
    pointer-events: none;
  }

  position: fixed;
  top: $spacing--small;
  left: 10px;
  right: 10px;

  font-size: $font-size--f;
  font-weight: $font-weight--bold;
  z-index: 2100;

  .StatusCard-inner {
    align-items: center;
  }

  @include gt(small) {
    display: none;
  }
}

.MultilistingUnavailableCard-text {
  line-height: $line-height--small;
  padding-right: $spacing--micro;
}

.MultilistingUnavailableCard-close {
  width: 30px;
  height: 30px;
}
