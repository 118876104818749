/*
  Border Radiuses
 */

$border-radius--small: 3px;
$border-radius--medium: 7px;
$border-radius--large: 10px;
$border-radius--jumbo: 500px;

/*
  Border Widths
 */

$border-width--medium: 2px;
$border-width--slim: 1px;

/*
  Borders
 */

$border-input: $border-width--medium solid $color-input;
$border-placeholder: $border-width--slim dashed $color-dark-gray;
$border-transparent-element: $border-width--slim solid rgba(255, 255, 255, 0.25);
$border-line-separator: $border-width--slim solid $palette--tint-3-grey;
$border-new-line-separator: $border-width--slim solid $palette--tint-4-grey;

// Design System 2019 © Romano

$border-radius-base: 5px;

$border-radius-inputSearch: 57px;
