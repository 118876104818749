@use "sass:math";

.ShortlistButton {
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 0 $spacing--small;
  box-shadow: $box-shadow-floating-button;

  &.isActive {
    background-color: $color-brand;
    border: 0;
    color: #fff;

    &:hover {
      background-color: $palette--dark-purple;
    }
  }

  svg {
    transition:
      transform 200ms,
      fill 300ms;
    position: relative;
    z-index: 1;
  }

  &.wasRemovedFromShortlist svg {
    animation: heart-wiggle 500ms;
    transform-origin: center bottom;
  }

  &:active {
    svg {
      transform: scale(0.4);
      transform-origin: center center;
    }
  }

  @keyframes heart-wiggle {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  &.noLabel {
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    transition: background-color 300ms;

    &:focus {
      outline: none;
      box-shadow:
        0px 0px 0px 2px white,
        0px 0px 0px 5px rgba(115, 45, 217, 0.5);
      color: $palette--black;
    }

    &.isHovered:not(.isActive),
    &:hover:not(.isActive) {
      background-color: $palette--tint-3-grey;
      border: 1px solid $palette--tint-4-grey;
      color: $palette--black;
    }

    &::after {
      content: "";
      width: 27px;
      height: 27px;
      position: absolute;
      bottom: 3px;
      left: 3px;
      border: 1px solid white;
      border-radius: 50%;
      transform: scale(0.1);
      transform-origin: center center;
      opacity: 0.4;
    }

    &.isActive::after {
      transform: scale(1);
      opacity: 0;
      transition:
        transform 300ms,
        opacity 200ms;
      transition-delay: 10ms, 400ms;
    }
  }
}

.ShortlistButton-label {
  margin-left: math.div($spacing--small, 3);
}
