@import "../../../styles/variables";

$designSystemButton-height--small: 30px;
$designSystemButton-height: 36px;
$designSystemButton-height--large: 40px;
$designSystemButton-height--xlarge: 60px; // default
$designSystemButton-border-radius: 25px;

$designSystemButton-line-height: 125%;

$Button--white-border: #e3e9f0;

// Default styles
.Button {
  height: $designSystemButton-height--large;
  padding: 0 $spacing--base * 6;
  border: none;
  border-radius: 100px;
  display: inline-block;
  line-height: $designSystemButton-height--large;
  transition:
    width 200ms ease-out,
    box-shadow 200ms ease-in-out,
    background-color 100ms ease-in;
  max-width: 100%;
  width: fit-content;
  cursor: pointer;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  @include ms-font-size(minus1);

  &:hover {
    cursor: pointer;
  }
}

// We have different sizes only available when the btn growWithText
.Button--growWithText {
  &.Button--small {
    padding: 0 $spacing--base * 3;
    @include ms-font-size(minus2);
    height: $designSystemButton-height--small;
    line-height: $designSystemButton-height--small;
  }
  &.Button--medium {
    @include ms-font-size(minus1);
    padding: 0 $spacing--base * 4;
    height: $designSystemButton-height;
    line-height: $designSystemButton-height;
  }
  &.Button--large {
    padding: 0 $spacing--base * 6;
    @include ms-font-size(minus1);
    height: $designSystemButton-height--large;
    line-height: $designSystemButton-height--large;
  }
  &.Button--xlarge {
    @include ms-font-size(1);
    padding: 0 $spacing--base * 6;
    height: $designSystemButton-height--xlarge;
    line-height: $designSystemButton-height--xlarge;
  }
}

.Button--fullWidth {
  width: 100%;
}

.Button.Button--primary {
  font-weight: bold;
  color: $palette--pure-white;
  background: $palette--lime-green;

  &:hover {
    background: $palette--lime-green-dark;

    &:disabled,
    &.Button--loading {
      background: $palette--pale-green !important;
    }
  }

  &:focus {
    background: $palette--lime-green;
    box-shadow: $box-shadow-focus-designSystemButtonPrimary;
    outline: 0;
  }
}

.Button--secondary {
  background: $palette--tint-2-grey;
  border: 1px solid $palette--tint-3-grey;
  color: $palette--black;

  &:hover {
    background: $palette--tint-3-grey;
    border: 1px solid $palette--tint-4-grey;
  }

  &:focus {
    background: $palette--tint-2-grey;
    box-shadow:
      0px 0px 0px 3px white,
      0px 0px 0px 5px $palette--tint-2-grey;
    outline: 0;
  }
}

.Button--secondaryBlue {
  background: $palette--pale-blue;
  border: 1px solid $palette--silver;
  color: $palette--black;

  &:hover {
    background: $palette--tint-2-grey;
    border: 1px solid $palette--tint-4-grey;
  }

  &:focus {
    background: $palette--tint-2-grey;
    box-shadow:
      0px 0px 0px 3px white,
      0px 0px 0px 5px $palette--tint-4-grey;
    outline: 0;
  }
}

.Button--negative {
  background: $palette--indian-red;
  color: $palette--pure-white;

  &:focus {
    background: $palette--indian-red;
    box-shadow:
      0px 0px 0px 3px white,
      0px 0px 0px 5px rgba(235, 87, 87, 0.5);
    outline: 0;
  }
}

.Button--brandPurple {
  background: $palette--wunder-brand-purple;
  color: $palette--pure-white;

  &:disabled,
  &.Button--loading {
    background: $palette--light-purple;
  }

  &:hover {
    background: $palette--dark-purple;

    &:disabled,
    &.Button--loading {
      background: $palette--light-purple;
    }
  }

  &:focus {
    box-shadow:
      0px 0px 0px 3px white,
      0px 0px 0px 5px rgba(115, 45, 217, 0.5);
    outline: 0;
  }
}

.Button--white {
  background: $palette--pure-white;
  border: 1px solid $Button--white-border;
  color: #575d64;

  &:disabled,
  &.Button--loading {
    background: $palette--tint-1-grey;
    border: 1px solid $palette--tint-2-grey;
    color: $palette--silver;
  }

  &:hover {
    background: $palette--tint-3-grey;
    border: 1px solid $palette--tint-4-grey;

    &:disabled,
    &.Button--loading {
      background: $palette--tint-1-grey;
      border: 1px solid $palette--tint-2-grey;
      color: $palette--silver;
    }
  }

  &:focus {
    box-shadow:
      0px 0px 0px 3px white,
      0px 0px 0px 5px rgba(115, 45, 217, 0.5);
    outline: 0;
  }
}

.Button--text {
  background: transparent;
  border: none;
  color: $palette--dark-grey;

  &:focus {
    box-shadow:
      0px 0px 0px 3px white,
      0px 0px 0px 5px $palette--tint-2-grey;
    outline: 0;
  }
}

// Disabled & loading states
.Button--disabled,
.Button--disabled:hover,
.Button--loading,
.Button:disabled {
  cursor: not-allowed;
  color: #fff;
}

.Button--primary.Button--disabled,
.Button--primary.Button--disabled:hover,
.Button--primary:disabled {
  background: $palette--pale-green;
}
.Button--secondary.Button--disabled,
.Button--secondary.Button--disabled:hover,
.Button--secondary:disabled {
  background: $palette--tint-1-grey;
  border: 1px solid $palette--tint-2-grey;
  color: $palette--silver;
}
.Button--negative.Button--disabled,
.Button--ngative.Button--disabled:hover,
.Button--negative:disabled {
  background: $palette--indian-red-light;
}
