.SvgSpinner {
  animation-name: svgSpinnerAnimation;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.22, 0.81, 0.87, 0.74);

  &.fast {
    animation-duration: 750ms;
  }
  &.normal {
    animation-duration: 1500ms;
  }
  &.slow {
    animation-duration: 3000ms;
  }
}

@keyframes svgSpinnerAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(720deg);
  }
}
