@use "sass:math";

@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.StatusCard {
  position: relative;
  z-index: 0;
  margin-left: $spacing--micro;
  border-radius: $border-radius--medium;

  &-outer {
    box-shadow: $box-shadow-card--bordered;
    border-radius: $border-radius--medium;
  }

  &:before {
    content: "";
    position: absolute;
    left: -$spacing--micro;
    top: 0;
    bottom: 0;
    width: 25px;
    background-color: $palette--light-gray-8;
    border-top-left-radius: $border-radius--medium;
    border-bottom-left-radius: $border-radius--medium;

    z-index: -1;
  }

  &--hideIndicator {
    margin-left: 0;
    border-left: 1px solid $palette--light-gray-8;

    &:before {
      display: none;
    }
  }

  &--intent_success:before {
    background-color: $color-success;
  }

  &--intent_brand:before {
    background-color: $color-brand;
  }

  &--intent_warning:before {
    background-color: $color-warning;
  }

  &--intent_danger:before {
    background-color: $palette--indian-red;
  }

  .StatusCard-inner {
    border-left: 0;
    box-shadow: none;
    padding: $spacing--small math.div($spacing--large, 3);
    display: flex;
    justify-content: space-between;
  }
  .StatusCard--small {
    padding: 14px math.div($spacing--large, 3);
  }
}

@include lt(xsmall) {
  .StatusCard {
    margin-left: 7px;
  }

  .StatusCard:before {
    left: -7px;
  }
}
