.CoverImage {
  margin: 0;
  position: relative;

  &-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
