.SignupForm {
  animation: SignupFormFadeInRight $login-transition-timing
    $login-transition-function forwards;
}

.SignupForm .InputWrapper {
  margin-top: $spacing--small;
  padding-bottom: $spacing--base;
  border-bottom: 1px solid $palette--tint-3-grey;

  &--hideBorder {
    border: transparent;
  }

  @include gt(medium) {
    margin-top: 23px;
  }
}

.SignupForm .InputWrapperEmail {
  grid-template-rows: auto 0;
  padding-bottom: 10px;

  .InputWithButton {
    min-height: initial;
  }
}

.InputWrapper-passwordInput .InputWrapper-children {
  position: relative;
}

.InputWrapper-passwordInput .ShowPasswordToggle {
  position: absolute;
  justify-content: center;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
}
.InputWrapper .InputTooltip-container {
  margin-top: $spacing--base;
  align-self: flex-end;
  position: absolute;
  top: 100%;
}
.SignupPasswordTooltip-popover {
  .popper-inner {
    max-width: 240px;
    @include gt(small) {
      max-width: 300px;
    }
  }
  .passwordTooltipContent {
    @include typo--body-2;
    font-family: $input-font-stack;

    .passwordTooltipContent-title {
      margin-bottom: 20px;
    }
    .passwordTooltipContent-list {
      list-style: disc;
      padding-left: 20px;

      li {
        position: relative;
        font-weight: $font-weight--regular;
      }
    }
  }
}

.SignupForm .InputText-input {
  width: 100%;
  min-width: initial;
}

.SignupForm-tosCheckbox {
  margin-top: $spacing--small;
  margin-bottom: $spacing--small;
}

.SignupForm-checkboxLabel {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  color: $palette--black;
}

.SignupForm-tosCheckbox .InputWrapper-error {
  margin-top: $spacing--base;
}

.SignupForm-tosLink {
  color: $palette--wunder-purple;
}

// Input group for firstName & lastName fields.
.SignupForm-inputGroup {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: $spacing--base * 4;

  @include gt(medium) {
    gap: $spacing--base * 5;
  }
}

.AuthComponent-Form--stickyFooter {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 85px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "inputs"
    "footer";
}

.AuthComponent-Form--stickyFooter .SignupForm-inputsContainer {
  grid-area: inputs;
}

.AuthComponent-Form--stickyFooter .SignupForm-footerContainer {
  z-index: 5;
  grid-area: footer;
  width: 100%;
  bottom: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: white;
  background: rgba(255, 255, 255, 0.95);
}

.SignupForm-submitButton {
  border: none;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  background: $palette--lime-green;
  border-radius: $spacing--base * 5;
  color: $palette--pure-white;
  cursor: pointer;

  width: 100%;
  height: 40px;

  &:hover {
    background: $palette--lime-green-dark;
  }

  &:disabled {
    background: $palette--pale-green;
  }

  @include gt(medium) {
    width: 145px;
  }
}

@keyframes SignupFormFadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.SignupForm .InputWrapperPhone {
  grid-template-rows: auto 30px auto 18px;
  @include gt(large) {
    grid-template-rows: auto 10px auto 18px;
  }
}

.phoneTooltipContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.phoneTooltipContent-title {
  @include typo--heading-5;
}
.phoneTooltipContent-text {
  @include typo--body-3;
}
.phoneNumber-sublabel-text {
  vertical-align: super;
  padding-left: 5px;
}

.SignupPhoneTooltip-popover {
  .popper-inner {
    max-width: 240px;
    @include gt(small) {
      max-width: 300px;
    }
  }
}
