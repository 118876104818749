.Textarea {
  display: block;
}

.Textarea-label {
  display: block;
  @include ms-font-size(minus1);
  line-height: $line-height--medium;
  color: $color-text-gray;
  cursor: pointer;
  text-align: left;
}

.Textarea-prefix {
  padding-left: $spacing--small;
  padding-top: 20px;
}

.Textarea-container {
  display: flex;
  width: 100%;
  border: 2px solid $color-input;
  border-radius: $border-radius--large * 2;
  max-width: 100%;
  line-height: 0;
  border: $border-input;
  border-radius: $border-radius--small;
}

.Textarea--invalid .Textarea-container {
  border-color: $color-error;
}

.Textarea:hover .Textarea-container {
  border-color: darken($color-input, 10);
}

.Textarea--focussed .Textarea-container,
.Textarea--focussed:hover .Textarea-container {
  outline: none;
  border-color: $color-brand;
}

.Textarea--invalid.Textarea--focussed .Textarea-container {
  border-color: $color-input;
}

.Textarea-textarea {
  width: 100%;
  max-width: 100%;
  border: 0;
  font-family: $primary-font-stack;
  @include ms-font-size(minus1);
  padding: ($spacing--small - $border-width--medium) $spacing--small;
  min-height: 240px;
  line-height: $line-height--medium;
  transition: border-color ease 100ms;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color-text-light-gray;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color-text-light-gray;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color-text-light-gray;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-text-light-gray;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-text-light-gray;
  }
}
