@import "../../styles/variables.scss";
@import "../../styles/media.scss";

@import "./CardButton/CardButton";
@import "./CardButtonGroup/CardButtonGroup";

.Card {
  background: $color-bg-white;
  box-shadow: $box-shadow-card;
  border-radius: $border-radius--medium;
  padding: $spacing--medium;
}

.Card-title {
  margin-bottom: $spacing--medium;
}

.Card--noPadding {
  padding: 0;
}

.Card--bordered {
  border: 1px solid $palette--light-gray-8;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.Card--centered {
  margin-left: auto;
  margin-right: auto;
}

.Card--small {
  max-width: 370px;
}

.Card--noShadow {
  box-shadow: none;
}
