.BankAccount-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BankAccountManager {
  .RichListItem-button--secondaryAction {
    padding: 3px;
  }
}
