.TopLoadingBar {
  position: fixed;
  top: 0;
  left: -100%;
  background: linear-gradient(39.64deg, #8457df 25.92%, #a03fc5 89.34%);
  height: 3px;
  z-index: 9999;
  width: 100%;
  animation-fill-mode: forwards;
}

.TopLoadingBar-loading {
  animation-timing-function: ease-in-out;
  animation-name: TopLoadingBar-animation-loading;
  animation-duration: 2s;
}

.TopLoadingBar-loaded {
  animation-timing-function: ease-in-out;
  animation-name: TopLoadingBar-animation-loaded;
  animation-duration: 0.5s;
}

@keyframes TopLoadingBar-animation-loading {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  25% {
    transform: translateX(20%);
  }

  50% {
    transform: translateX(50%);
  }
  75% {
    transform: translateX(60%);
  }
  100% {
    transform: translateX(80%);
  }
}

@keyframes TopLoadingBar-animation-loaded {
  0% {
    opacity: 1;
    transform: translateX(80%);
  }

  40% {
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
  }
}
