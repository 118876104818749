.grid-row {
  @include clearfix();
  margin: 0 0 $spacing--small;
}

.grid-col {
  float: left;
  padding: 0 $spacing--small;

  &:first-child,
  &--first {
    padding-left: 0;
  }

  &:last-child,
  &--last {
    padding-right: 0;
  }

  &--half {
    width: 50%;
  }

  &--third {
    width: 33.333%;
  }

  &--quarter {
    width: 25%;
  }
}

.grid-row--collapse-on-small {
  @include lt(small) {
    margin: 0;

    .grid-col {
      width: 100%;
      padding: 0;
      margin: 0 0 $spacing--small;
    }
  }
}

.grid-row--collapse-on-xsmall {
  @include lt(xsmall) {
    margin: 0;

    .grid-col {
      width: 100%;
      padding: 0;
      margin: 0 0 $spacing--small;
    }
  }
}
