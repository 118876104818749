.AppHeader-DesktopLinkList {
  display: none;
  align-items: center;

  &-item {
    display: inline-flex;
    margin-right: 2px;
  }

  .AppHeader-Link {
    padding: 9px $spacing--small;
    border-radius: $border-radius-base * 4;

    &-badge {
      margin-right: -7px;
    }
  }

  @include gt(large) {
    display: flex;
  }
}

// Theme - Basic Transparent
.AppHeader-theme--basicTransparent .AppHeader-DesktopLinkList {
  .AppHeader-Link--active {
    background: $palette--tint-2-grey;
  }

  .AppHeader-Link:hover:not(.AppHeader-Link--active) {
    background: $palette--tint-1-grey;
  }

  .AppHeader-Link-text {
    color: $palette--black;
  }

  .AppHeader-Link-badge {
    background: $palette--wunder-purple;
    color: $palette--pure-white;
  }
}

// Theme - Transparent
.AppHeader-theme--transparent .AppHeader-DesktopLinkList {
  .AppHeader-Link--active {
    background: rgba($color: $palette--black, $alpha: 0.2);
  }

  .AppHeader-Link:hover:not(.AppHeader-Link--active) {
    background: rgba($color: $palette--black, $alpha: 0.1);
  }

  .AppHeader-Link-text {
    color: $palette--pure-white;
  }

  .AppHeader-Link-badge {
    background: $palette--pure-white;
    color: $palette--black;
  }
}
// Theme - Brand Gradient
.AppHeader-theme--brandGradient .AppHeader-DesktopLinkList {
  .AppHeader-Link--active {
    background: rgba($color: $palette--black, $alpha: 0.2);
  }

  .AppHeader-Link:hover:not(.AppHeader-Link--active) {
    background: rgba($color: $palette--black, $alpha: 0.1);
  }

  .AppHeader-Link-text {
    color: $palette--pure-white;
  }

  .AppHeader-Link-badge {
    background: $palette--pure-white;
    color: $palette--black;
  }
}

// Theme - White
.AppHeader-theme--white .AppHeader-DesktopLinkList {
  .AppHeader-Link--active {
    background: $palette--tint-2-grey;
  }

  .AppHeader-Link:hover:not(.AppHeader-Link--active) {
    background: $palette--tint-1-grey;
  }

  .AppHeader-Link-text {
    color: $palette--black;
  }

  .AppHeader-Link-badge {
    background: $palette--wunder-purple;
    color: $palette--pure-white;
  }
}

// Theme - Image
.AppHeader-theme--image .AppHeader-DesktopLinkList {
  .AppHeader-Link--active {
    background: rgba($color: $palette--black, $alpha: 0.25);
  }

  .AppHeader-Link:hover:not(.AppHeader-Link--active) {
    background: rgba($color: $palette--black, $alpha: 0.15);
  }

  .AppHeader-Link-text {
    color: $palette--pure-white;
  }

  .AppHeader-Link-badge {
    background: $palette--pure-white;
    color: $palette--black;
  }
}
