.Drawer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.25);
}

.Drawer-inner {
  background: white;
  opacity: unset;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
  border-radius: 10px 10px 0px 0px;
  max-height: 95%;
}

.Drawer-sliderContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  // It makes the menu scrollable on iOS Safari
  min-height: 0;
}

.Drawer-enter {
  background: none;
  visibility: hidden;
  opacity: 0;
  .Drawer-inner {
    transform: translateY(100%);
  }
}
.Drawer-enter-active {
  visibility: inherit;
  opacity: 1;
  transition: all 250ms;
  background: rgba(0, 0, 0, 0.25);
  .Drawer-inner {
    transition: transform 250ms;
    transform: translateY(0%);
  }
}

.Drawer-exit {
  opacity: 1;
  background: rgba(0, 0, 0, 0.25);
  .Drawer-inner {
    transform: translateY(0%);
  }
}
.Drawer-exit-active {
  .Drawer-inner {
    transition: transform 250ms;
    transform: translateY(100%);
  }
  opacity: 0;
  transition: opacity 250ms;
}

.Drawer-slider {
  width: 100%;
  height: $spacing--medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $spacing--small;
}

.Drawer-children {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: $spacing--small;
}
