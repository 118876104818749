.AppHeaderUserMenu-ToggleButton-desktop {
  display: none;

  align-items: center;
  padding: 7px $spacing--small 7px 7px;
  border-radius: 27px;

  margin-right: -$spacing--small;

  cursor: pointer;

  background: none;
  border: 0;
  outline: none;

  @include gt(small) {
    display: inline-flex;
  }
}

.AppHeaderUserMenu-ToggleButton-mobile {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;

  border-radius: 50%;

  outline: none;

  @include gt(large) {
    display: none;
  }

  &--loggedIn {
    @include gt(small) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.AppHeaderUserMenu-notifications {
  width: 18px;
  height: 18px;

  position: absolute;
  bottom: -$spacing--base;
  right: -$spacing--base;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-weight: $font-weight--medium;
  font-size: $font-size--h;

  border-radius: 50%;
}

// Themes
.AppHeader-theme--basicTransparent {
  .AppHeaderUserMenu-ToggleButton--active {
    background: $palette--tint-2-grey;
  }

  .AppHeaderUserMenu-ToggleButton-desktop:hover:not(
      .AppHeaderUserMenu-ToggleButton--active
    ) {
    background: $palette--tint-1-grey;
  }

  .AppHeaderUserMenu-ToggleButton-mobile {
    background: $palette--tint-3-grey;
    border: 1px solid $palette--tint-4-grey;
  }

  .AppHeaderUserMenu-notifications {
    background: $palette--wunder-purple;
    border: 1px solid $palette--pure-white;
    color: $palette--pure-white;
  }

  .AppHeaderUserMenu-BurgerMenuIcon-line {
    fill: $palette--black;
  }
}

.AppHeader-theme--transparent {
  .AppHeaderUserMenu-ToggleButton--active {
    background: rgba($color: $palette--black, $alpha: 0.2);
  }

  .AppHeaderUserMenu-ToggleButton-desktop:hover:not(
      .AppHeaderUserMenu-ToggleButton--active
    ) {
    background: rgba($color: $palette--black, $alpha: 0.1);
  }

  .AppHeaderUserMenu-ToggleButton-mobile {
    background: rgba($color: $palette--pure-white, $alpha: 0.2);
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.35);
  }

  .AppHeaderUserMenu-notifications {
    background: $palette--pure-white;
    border: 1px solid $palette--pure-white;
    color: $palette--black;
  }

  .AppHeaderUserMenu-Avatar {
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.45);
  }

  .AppHeaderUserMenu-BurgerMenuIcon-line {
    fill: $palette--pure-white;
  }
}

.AppHeader-theme--white {
  .AppHeaderUserMenu-ToggleButton--active {
    background: $palette--tint-2-grey;
  }

  .AppHeaderUserMenu-ToggleButton-desktop:hover:not(
      .AppHeaderUserMenu-ToggleButton--active
    ) {
    background: $palette--tint-1-grey;
  }

  .AppHeaderUserMenu-ToggleButton-mobile {
    background: $palette--tint-2-grey;
    border: 1px solid $palette--tint-3-grey;
  }

  .AppHeaderUserMenu-notifications {
    background: $palette--wunder-purple;
    border: 1px solid $palette--pure-white;
    color: $palette--pure-white;
  }

  .AppHeaderUserMenu-BurgerMenuIcon-line {
    fill: $palette--black;
  }
}

.AppHeader-theme--image {
  .AppHeaderUserMenu-ToggleButton--active {
    background: rgba($color: $palette--black, $alpha: 0.25);
  }

  .AppHeaderUserMenu-ToggleButton-desktop:hover:not(
      .AppHeaderUserMenu-ToggleButton--active
    ) {
    background: rgba($color: $palette--black, $alpha: 0.15);
  }

  .AppHeaderUserMenu-ToggleButton-mobile {
    background: rgba($color: $palette--black, $alpha: 0.25);
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.25);
  }

  .AppHeaderUserMenu-notifications {
    background: $palette--pure-white;
    color: $palette--black;
  }

  .AppHeaderUserMenu-Avatar {
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.45);
  }

  .AppHeaderUserMenu-BurgerMenuIcon-line {
    fill: $palette--pure-white;
  }
}

.AppHeader-theme--brandGradient {
  .AppHeaderUserMenu-ToggleButton--active {
    background: rgba($color: $palette--black, $alpha: 0.2);
  }

  .AppHeaderUserMenu-ToggleButton-desktop:hover:not(
      .AppHeaderUserMenu-ToggleButton--active
    ) {
    background: rgba($color: $palette--black, $alpha: 0.1);
  }

  .AppHeaderUserMenu-ToggleButton-mobile {
    background: rgba($color: $palette--pure-white, $alpha: 0.2);
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.35);
  }

  .AppHeaderUserMenu-notifications {
    background: $palette--pure-white;
    border: 1px solid $palette--pure-white;
    color: $palette--black;
  }

  .AppHeaderUserMenu-Avatar {
    border: 1px solid rgba($color: $palette--pure-white, $alpha: 0.45);
  }

  .AppHeaderUserMenu-BurgerMenuIcon-line {
    fill: $palette--pure-white;
  }
}

.AppHeaderUserMenu-ToggleButton--userIsLoggedIn {
  .Avatar {
    @include gt(small) {
      margin-right: 7px;
    }
  }
}
