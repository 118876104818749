.Listing {
  border-radius: 7px;
  position: relative;
  background-color: white;
  background-size: cover;
  background-position: center;
  width: 100%;
  box-sizing: border-box;
  box-shadow: $box-shadow-card--idle;
  transition: box-shadow 300ms ease-in-out;

  &::after {
    content: "";
    display: none;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid $palette--tint-4-grey;
    margin-left: -2px;
    border-radius: 7px;
  }

  &:hover::after {
    display: flex;
  }

  &:hover {
    box-shadow: none;
  }

  .ListingPrice {
    margin-top: 5px;
    padding: 0;
  }

  .ImageGallery {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .ImageGallery-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      transition: none;
    }

    .ImageGallery-navButton {
      width: 44px;
      z-index: 3;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  &--highlight {
    box-shadow: 0 0 0 2px $palette--wunder-purple-highlight-element;
  }

  .Listing-ShortlistButton.ShortlistButton {
    position: absolute;
    top: ($spacing--base * 2);
    right: ($spacing--base * 2);

    width: 36px;
    height: 36px;
    box-shadow: none;
    border: 1px solid $palette--tint-3-grey;
    z-index: 5;
  }
}

.Listing-description {
  padding: 12px 30px;
  font-size: $font-size;
  line-height: 24px;
  position: relative;
}

.Listing-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.Listing-descriptionTitle {
  font-weight: bold;
  height: 3em;
  font-size: $font-size;
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.Listing-ShortlistButton.ShortlistButton .isActive .isHovered {
  background-color: $color-brand;
}

.Listing-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    margin-top: 50px;
  }

  &-flexible {
    margin-top: 12px;
  }
}

.Listing-ImageGallery {
  height: 0;
  padding-bottom: 72.97%;
  background: $color-light;
  position: relative;
  border-radius: 7px 7px 0 0;
}

.Listing-ImageGallery-link {
  position: absolute;
  top: 0;
  left: 44px;
  right: 44px;
  bottom: 0;
  z-index: 1;
}
