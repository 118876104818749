.AuthComponent-EmailVerification {
  @include gt(medium) {
    max-width: 575px;
    margin-top: 250px;
  }
}

.AuthComponent-EmailVerification-title {
  font-size: 24px;
  font-weight: $font-weight--bold;
  line-height: $line-height--large;
  color: $palette--black;
  text-align: center;

  @include gt(medium) {
    font-size: 36px;
    text-align: left;
  }
}

.AuthComponent-EmailVerification-description {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  line-height: $line-height--small;
  color: $palette--dark-grey;
  margin-top: $spacing--base;
  text-align: center;
  word-break: break-word;

  @include gt(medium) {
    font-size: $font-size--e;
    text-align: left;
  }
}

.AuthComponent-EmailVerification-email {
  color: $palette--black;
  font-weight: $font-weight--bold;
}

.AuthComponent-EmailVerification-button {
  color: $palette--dark-grey;
  font-weight: $font-weight--medium;
  font-size: $font-size--g;
  cursor: pointer;

  background: none;
  border: none;

  margin-top: $spacing--base * 5;

  @include lt(medium) {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.AuthComponent-EmailVerification-button .IconTextButton-icon {
  border: 0;
}
