.DateWidget {
  background: white;

  &-clearButton {
    font-family: $primary-font-stack;
    @include ms-font-size(minus1);

    line-height: normal;
    height: auto;
    background: none;
    padding: 0;
    border: 0;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 0.5em;
    color: $color-danger;
    width: 100%;
    &:focus {
      outline: 0;
    }
    &:hover {
      text-decoration: none;
    }
  }

  &-monthNavigation {
    @include clearfix();
    text-align: center;
    max-width: 220px;
    margin: 0 auto;

    button {
      background: none;
      border: 0;
      padding: 0 5px;
      cursor: pointer;
      @include ms-font-size(minus1);
      line-height: $line-height--medium;
    }

    button[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &-currentMonth {
    display: inline-block;
  }

  &-viewPreviousMonth {
    float: left;
  }

  &-viewNextMonth {
    float: right;
  }

  &-viewPreviousMonth::before {
    @include icon($icon--chevron-left);
  }

  &-viewNextMonth::before {
    @include icon($icon--chevron-right);
  }

  &-mobileLabel {
    text-align: center;
    margin-bottom: $spacing--small;
  }
}

@mixin mobileUI {
  .Popover-card {
    background: none;
    border: none;
    padding: 0;
  }

  .DateWidget-mask {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    animation: fade-in 250ms forwards;

    &.is-hidden {
      animation: fade-out 250ms forwards;
    }
  }

  .DateWidget-inner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: $box-shadow-popover;
    transform: translate3d(0, 100%, 0);
    animation: slide-in-from-bottom 250ms forwards;
    z-index: 1000;
    background: #ffffff;

    .btn-link {
      margin-bottom: 1.5em;
    }

    &.is-hidden {
      animation: slide-out-to-bottom 250ms forwards;
    }
  }

  .DateWidget {
    padding: 1.5em 1.5em;
  }

  .DateMonthView {
    margin: 0 auto;
  }
}

// when browser width > 460 and <= 992 pixels - show mobile UI
// except if on SRP, in which case show desktop UI
@media screen and (max-width: 992px) {
  .Popover--date-input:not(.onListingsPage) {
    @include mobileUI();
  }
}

// always show mobile UI if browser width <= 460
@media screen and (max-width: 460px) {
  .Popover--date-input {
    @include mobileUI();
  }
}

// always show mobile UI if browser height <= 545
@media screen and (max-height: 545px) {
  .Popover--date-input {
    @include mobileUI();
  }
}

@keyframes slide-in-from-bottom {
  0% {
    transform: translate3d(0, 100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-to-bottom {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
