.ErrorPage {
  display: flex;
  flex-direction: column;

  .ErrorPage-fullHeightContainer {
    min-height: 100vh;
  }

  &-mainContainer {
    padding: 0 $spacing--small;
    margin: 0 auto;
    margin-top: 180px;
    max-width: $page-max-width--narrow;
    text-align: center;
  }

  &-title {
    font-weight: bold;
    font-size: 36px;
    line-height: 1.3;
  }

  &-subTitle {
    margin: 0 auto;
    margin-top: $spacing--small;
    max-width: 650px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  &-actionBar {
    margin-top: $spacing--medium;
  }

  &-actionBarItem {
    margin: 0 10px;
  }

  @include lt(large) {
    &-mainContainer {
      margin-top: 60px;
    }

    &-title {
      font-size: $font-size--b;
    }

    &-actionBarItem {
      display: block;

      & + & {
        margin-top: 5px;
      }
    }
  }
}
