@import "../../../styles/variables";

.IconButton {
  display: flex;
  align-items: center;
  background: none;
  @include ms-font-size(minus1);
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  transition: box-shadow 200ms ease-in-out;
  line-height: 135%;
  display: flex;
  align-items: center;
  color: $palette--black;
  border-radius: $spacing--base * 5;
  width: fit-content;
  max-width: 100%;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > .IconButton-label {
    margin-left: $spacing--base * 2;
  }

  &.IconButton--alignIconToTheRight > .IconButton-label {
    margin-right: $spacing--base * 2;
  }

  &:hover {
    cursor: pointer;

    .IconButton-icon {
      background: $palette--tint-3-grey;
      border: 1px solid $palette--tint-4-grey;
    }
  }

  &:focus {
    outline: none;
    border-radius: $spacing--base * 5;
    box-shadow: $box-shadow-focus-iconButton;
  }

  &:disabled,
  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: $palette--silver;
  }
}

.IconButton--alignIconToTheRight {
  flex-direction: row-reverse;
}

.IconButton-icon {
  background: $palette--tint-2-grey;
  border: 1px solid $palette--tint-3-grey;
  box-sizing: border-box;
  border-radius: $spacing--base * 5;
  width: $iconSize;
  height: $iconSize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IconButton--small {
  .IconButton-icon {
    width: $iconSize--small;
    height: $iconSize--small;
  }
}

.IconButton--large {
  & > .IconButton-label {
    margin-left: $spacing--base * 3;
  }

  &.IconButton--alignIconToTheRight > .IconButton-label {
    margin-right: $spacing--base * 3;
  }

  .IconButton-icon {
    width: $iconSize--large;
    height: $iconSize--large;
  }
}

.IconButton--extraLarge {
  & > .IconButton-label {
    margin-left: $spacing--base * 3;
  }

  &.IconButton--alignIconToTheRight > .IconButton-label {
    margin-right: $spacing--base * 3;
  }

  font-size: $font-size--e;

  .IconButton-icon {
    width: $iconSize--extraLarge;
    height: $iconSize--extraLarge;
  }
}

.IconButton-defaultBorder {
  border: default;
}
