.AppHeader-LanguageDropdown {
  display: inline-flex;
  position: relative;
}

.AppHeader-LanguageDropdownButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: none;

  border: 0;
  border-radius: $border-radius-base * 4;
  outline: none;

  cursor: pointer;
}

.AppHeader-LanguageDropdownList {
  position: absolute;
  top: 45px;
  right: -10px;

  min-width: 140px;
  padding: $spacing--base;

  box-sizing: border-box;
  border-radius: 7px;
  border: 1px solid $palette--tint-3-grey;
  background: $palette--pure-white;

  z-index: 10;
}

.AppHeader-LanguageDropdownLink {
  display: flex;
  align-items: center;
  padding: 7px $spacing--base;
  border-radius: $border-radius-base;

  &-flag {
    display: inline-flex;
  }

  &-text {
    display: inline-flex;
    margin-left: $spacing--base * 2;

    font-size: $font-size--f;
    font-weight: $font-weight--medium;
    color: $palette--black;
  }
}

.AppHeader-LanguageDropdownLink:not(
    .AppHeader-LanguageDropdownLink--active
  ):hover {
  background-color: $palette--tint-1-grey;
}

// Theme - Transparent
.AppHeader-theme--transparent {
  .AppHeader-LanguageDropdownButton:hover {
    background-color: rgba($color: $palette--black, $alpha: 0.1);
  }

  .AppHeader-LanguageDropdownButton--active {
    background-color: rgba($color: $palette--black, $alpha: 0.1);
  }
}

// Theme - White
.AppHeader-theme--white {
  .AppHeader-LanguageDropdownButton:hover {
    background-color: $palette--tint-1-grey;
  }

  .AppHeader-LanguageDropdownButton--active {
    background-color: $palette--tint-2-grey;
  }
}

// Theme - Image
.AppHeader-theme--image {
  .AppHeader-LanguageDropdownButton:hover {
    background-color: rgba($color: $palette--black, $alpha: 0.15);
  }

  .AppHeader-LanguageDropdownButton--active {
    background-color: rgba($color: $palette--black, $alpha: 0.25);
  }
}
