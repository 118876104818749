.MultilistingBadge {
  display: inline-block;
  position: relative;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 12px 13px 13px 40px;
  border-radius: $border-radius--medium;
  line-height: $line-height--micro;

  &:before {
    @include icon($icon--building);
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
  }

  @include lt(small) {
    padding-left: 50px;

    &:before {
      content: "";
      transform: none;
      top: 12px;
      left: 15px;
      width: 22px;
      height: 22px;
      background-image: url("/public/images/info-icon.svg");
    }
  }
}

.MultilistingBadge--fullWidth {
  display: block;
  width: 100%;
}

.MultilistingBadge--angular {
  border-radius: 0;
  border-bottom-left-radius: $border-radius--medium;

  @include lt(small) {
    border-bottom-left-radius: 0;
  }
}

.MultilistingBadge-label {
  margin-right: 0.5em;
  font-weight: $font-weight--bold;
}
