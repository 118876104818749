.StickyHeader {
  z-index: 110;

  width: 100%;

  position: fixed;
  top: 0;
  left: 0;

  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-duration: 0.5s;

  &--hidden {
    transform: translateY(-100%);
  }
}

// StickyHeader's padding-right changes when .card-modal-open is present
// on <html>. See src/components/CardModal/CardModal.scss
