@import "../../../styles/media.scss";
@import "../../../styles/variables.scss";

.AppHeader-LogoLink {
  display: inline-flex;
}

.AppHeader-WunderflatsLogoBadge {
  display: inline-flex;

  @include gt(xlarge) {
    display: none;
  }
}

.AppHeader-WunderflatsLogo {
  display: none;

  @include gt(xlarge) {
    display: inline-flex;
  }
}
