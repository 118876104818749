@import "../../styles/variables.scss";

.Avatar {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $palette--gold;
  width: 36px;
  height: 36px;

  &-letter {
    font-size: $font-size--e;
    font-weight: $font-weight--medium;
    color: $palette--pure-white;
  }

  &-notifications {
    background: $palette--wunder-purple;
    border: 1px solid $palette--pure-white;
    color: $palette--pure-white;
    width: 18px;
    height: 18px;

    position: absolute;
    bottom: -$spacing--base;
    right: -$spacing--base;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-weight: $font-weight--medium;
    font-size: $font-size--h;

    border-radius: 50%;
  }
}
