.EmailAddress {
  display: inline-block;
  color: $color-brand;

  &-emailIcon:before {
    @include icon($icon--envelope);
    font-style: normal;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    display: inline-block;
    color: $color-brand;
    margin-right: 5px;
  }

  &-emailAddress {
    color: $color-brand;
  }

  &--subtle {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  &--transparent,
  &--transparent &-emailIcon:before {
    color: white;
  }
}
