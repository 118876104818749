.ListingPriceText__wrapper {
  color: $palette--dark-grey;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 22px;
}

.ListingPriceText__label {
  @include typo--label;
}

.ListingPriceText__value {
  @include typo--heading-3;
}
