.ListingDetailsPage-VerifiedBadgeButton {
  display: flex;
  align-items: center;

  cursor: pointer;

  background: none;
  outline: none;
  border: 0;

  margin-top: $spacing--small;

  @include gt(medium) {
    margin-top: 0;
  }
}

.ListingsDetailsPage-VerifiedBadgeButton-text {
  color: $palette--dark-grey;
  font-family: $primary-font-stack;
  font-weight: $font-weight--bold;
  font-size: $font-size--e;
  text-align: right;

  margin-left: 4px;
  margin-right: 6px;
}

.VerifiedBadgePopover-content {
  padding: $spacing--small + $spacing--base;

  @include gt(medium) {
    max-width: 335px;
    padding: 0;
  }
}

.VerifiedBadgePopover-contentTitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: $font-weight--bold;
  font-size: $font-size--d;
  color: $palette--black;
  margin-bottom: 25px;
}

.VerifiedBadgePopover-separator {
  border: 0;
  border-top: 1px solid $palette--tint-3-grey;
}

.VerifiedBadgePopover-contentInfo {
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: $line-height--small;
  color: $palette--black;
  margin-bottom: $spacing--small;
}

.VerifiedBadgePopover-contact {
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  line-height: $line-height--small;
  color: $palette--black;
  margin-top: $spacing--small;
  margin-bottom: $spacing--medium;
}

.VerifiedBadgePopover-contact-email {
  color: $palette--black;
}

.ListingDetailsPage-VerifiedBadgeGotItButton {
  color: $palette--black;
  font-weight: $font-weight--medium;
  background: $palette--tint-2-grey;
  border: 1px solid $palette--tint-3-grey;

  &:hover {
    background: $palette--tint-3-grey;
    border-color: $palette--tint-4-grey;
  }
}

.VerifiedBadgePopover-closeButton {
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;

  padding: 0;

  background-color: $palette--tint-2-grey;
  border-radius: 50%;
}
