.ViennaStayDurationMessage {
  margin: $spacing--medium 0;
  box-shadow: none;
  flex-wrap: wrap;

  @include gt(768px) {
    flex-wrap: nowrap;
    margin: $spacing--medium 0 0;
  }
}

.ViennaStayDurationMessage .Card {
  align-items: center;
}

.ViennaStayDurationMessage-dismissButton {
  margin: $spacing--base * 4 auto 0 0;

  @include gt(719px) {
    margin: 0 0 0 auto;
  }
}

.ViennaStayDurationMessage-title {
  font-weight: $font-weight--bold;
  font-size: $font-size--e;
  color: $color-2019--font-primary;
  line-height: 24px;
}

.ViennaStayDurationMessage-content {
  font-size: $font-size--f;
  color: $palette--dark-grey;
  line-height: 21px;
}

.ViennaStayDurationMessage-buttonWrapper {
  display: inline-block;
  margin-top: $spacing--small;
  margin-bottom: $spacing--small;
}

.ViennaStayDurationMessage-button {
  overflow: visible !important;
}
