@use "sass:math";

$ListingItem-image-minSize: 185px;

.ListingItem {
  display: flex;
  margin-top: $spacing--medium;
  flex-direction: column;
  position: relative;
  z-index: 1;

  @include gt(small) {
    padding-bottom: $spacing--medium;
    flex-direction: row;
    border-bottom: 1px solid $color-border-gray;
  }

  .ImageGallery {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .ImageGallery-container {
      top: 0;
      height: 100%;

      .CoverImage-image {
        border-radius: $border-radius-base;
      }

      .ImageGallery-navButton {
        width: 20%;
      }
    }
  }
}

.ListingItem-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  &.mobile {
    display: block;
  }

  &.notMobile {
    display: none;
    bottom: $spacing--medium;
  }

  @include gt(medium) {
    &.mobile {
      display: none;
    }

    &.notMobile {
      display: block;
    }
  }
}

.ListingItem-image-outer {
  width: 100%;

  @include gt(small) {
    width: calc(40% - #{$spacing--medium});
    min-width: 250px;
  }

  @include gt(xlarge) {
    width: calc(47% - #{$spacing--medium});
  }

  @include gt(widescreen) {
    width: calc(44% - #{$spacing--medium});
  }
}

.ListingItem-image {
  height: 0;
  padding-bottom: 66%;
  position: relative;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.05);

  @include gt(small) {
    height: 100%;
    margin-right: $spacing--medium;
    min-height: $ListingItem-image-minSize;
    padding-bottom: 0;
  }
}

.ListingItem-info {
  color: inherit;
  margin-top: $spacing--small;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.ListingItem-deleteButton.DeleteButton {
  z-index: 3;
  margin-left: auto;

  @include gt(small) {
    margin-top: auto;
  }

  @include lt(xsmall) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.ListingItem-price {
  font-size: $font-size--d;
}

.ListingItem-title {
  font-size: $font-size--e;
  line-height: $line-height--small;
  padding-right: $spacing--medium;

  @include gt(small) {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

.ListingItem-region,
.ListingItem-brief {
  font-size: $font-size--f;
  color: $color-2019--font-secondary;
}

.ListingItem-brief {
  line-height: 1;
}

.ListingItem-perMonth {
  color: $palette--dark-gray-4;
  font-weight: 100;
  font-size: $font-size--g;
}

.ListingItem-separator {
  margin: 0 math.div($spacing--medium, 3);
}
