@import "../../styles/variables.scss";

.BankAccountForm {
  width: 100%;
  position: relative;
  display: block;

  .Selectbox,
  .Selectbox-select {
    width: 100%;
  }
}

.BankAccountForm-title {
  @include typo--heading-2;
  margin-bottom: $spacing--base * 5;
}

.BankAccountForm-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $spacing--small;
  margin-top: 45px;

  @include gt(medium) {
    flex-direction: row;
    justify-content: flex-start;
  }
}
