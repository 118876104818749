.DateMonthView {
  border-collapse: collapse;
  margin-top: 1em;
  font-size: $font-size--f;
  text-align: center;
  line-height: normal;

  th {
    padding: 0.3em;
    text-transform: uppercase;
  }

  td {
    padding: 0.5em;
  }

  &-day--inInterval {
    background: $palette--pale-purple;
    color: white;
  }

  &-day--inInterval#{&}-day--available {
    background: $palette--light-purple;
    color: white;
  }

  &-day--unavailable#{&}-day--inInterval {
    background: $palette--light-purple;
    color: white;
  }

  &-day--unavailable#{&}-day--blocked,
  &-day--blocked {
    background: $palette--light-gray-9;
    color: white;
    cursor: not-allowed;
  }

  &-day--available:hover {
    background: $color-brand;
    color: white;
  }

  &-day--unavailable {
    background: $palette--light-gray-9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  &-day--available {
    cursor: pointer;
  }

  &-day--today {
    color: $palette--wunder-purple;
  }

  &-day--selected {
    font-weight: bold;
    background: $palette--wunder-purple;
    color: white;
  }
}
