.StyledCheckbox {
  display: inline-flex;
  flex-direction: row;
  position: relative;

  &--expand {
    display: flex;
    justify-content: space-between;
  }
}

// Hide only visually, but have it available for screen readers.
// https://github.com/h5bp/html5-boilerplate/blob/9a176f57af1cfe8ec70300da4621fb9b07e5fa31/src/css/main.css#L121
.StyledCheckbox-input {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

.StyledCheckbox-iconContainer {
  width: 30px;
  height: 30px;

  min-height: 30px;
  min-width: 30px;

  background-color: $palette--pure-white;
  border: 1px solid $palette--silver;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: 50%;

  &:hover {
    border: 1px solid $palette--grey;
  }

  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: " ";
    background-image: none;
    background-color: $palette--wunder-purple;
    display: block;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
  }
}

.StyledCheckbox-iconContainer-icon {
  visibility: hidden;
}

.StyledCheckbox-input:checked
  + .StyledCheckbox-iconContainer
  .StyledCheckbox-iconContainer-icon {
  visibility: visible;
}

.StyledCheckbox-input:focus + .StyledCheckbox-iconContainer {
  box-shadow:
    0px 0px 0px 1px rgba(255, 255, 255, 1),
    0px 0px 0px 4px rgba(115, 45, 217, 0.5);
}

.StyledCheckbox-input:disabled + .StyledCheckbox-iconContainer {
  cursor: not-allowed;

  &:hover {
    border: 1px solid $palette--silver;
  }

  &:before {
    background-color: $palette--silver;
  }
}

.StyledCheckbox-input:checked + .StyledCheckbox-iconContainer {
  border: 0;

  &:before {
    opacity: 1;
  }
}

.StyledCheckbox-text {
  margin-top: $spacing--base;

  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;

  &--right {
    padding-left: $spacing--base * 2;
  }

  &--left {
    padding-right: $spacing--base * 2;
  }
}

.StyledCheckbox-direction {
  &--left {
    flex-direction: row-reverse;
  }
}
.StyledCheckbox-label {
  display: block;
  color: $palette--black-gray;
  font-size: $font-size--f;
  line-height: 21px;
}

.StyledCheckbox-error {
  display: block;

  font-size: $font-size--g;
  line-height: 18px;
  color: $palette--indian-red;

  margin-top: 4px;
}

.StyledCheckbox-input:disabled ~ .StyledCheckbox-text > .StyledCheckbox-label {
  cursor: not-allowed;
  color: $palette--grey;
}
