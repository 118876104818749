.PasswordInput-criteria {
  font-size: 12px;
  display: flex;
  flex-direction: column;

  margin-top: 4px;

  .criteria {
    &::before {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

    &.criteriaSubmittedUnmatched {
      &::before {
        content: url("/public/images/red_cross_checkmark.svg");
      }
      color: #ff0000;
    }

    &.criteriaNotMatched {
      &::before {
        content: url("/public/images/grey_cross_checkmark.svg");
      }
      color: #575d64;
    }

    &.criteriaMatched {
      &::before {
        content: url("/public/images/green_tick_checkmark.svg");
      }
      color: #47a82d;
    }
  }
}

.PasswordInput-error {
  font-size: 12px;
  color: #ff0000;
  margin-top: 4px;
}
