.ErrorIndicator {
  &--invalid {
    .Input,
    .Select,
    .Select-select,
    .Textarea,
    .input,
    .textarea,
    .InputText-input,
    .Input-affixWrapper,
    .Textarea-container,
    .DateTextInput-inputs,
    .Textarea-container:hover {
      border-color: $color-error;
    }

    .Input-label,
    .InputWrapper-label,
    .DateTextInput-label,
    .Select-label {
      color: $color-error;
    }
  }

  &--full {
    display: block;
  }

  &-message {
    font-size: 12px;
    display: block;
  }

  &--error {
    color: $color-error;
  }

  &--required {
    color: $color-required;
  }

  &--highlight,
  &--required {
    .Input,
    .Select,
    .Select-select,
    .Textarea,
    .input,
    .textarea,
    .Input-affixWrapper,
    .Textarea-container,
    .Textarea-container:hover,
    .DateTextInput-inputs {
      border-color: $color-required;
    }
  }

  &--required {
    .Input-label,
    .Select-label {
      color: $color-required;
    }
  }
}
