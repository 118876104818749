.FormCardModal {
  .SimpleModal-inner {
    background: #fff;
    box-shadow: $box-shadow-modal;
    border-radius: 7px;
    width: 480px;
    max-width: calc(100% - #{2 * $spacing--micro});
  }
}

.FormCardModal-main {
  padding: $spacing--medium $spacing--medium 0 $spacing--medium;

  p {
    margin-bottom: $spacing--small;
    line-height: $line-height--small;
  }

  .ErrorMessage {
    margin-top: $spacing--small;
  }
}

.FormCardModal-footer {
  margin-top: $spacing--medium;
}
