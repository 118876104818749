.popper-reference {
  width: max-content;
  height: max-content;
}

.popper-InnerReference {
  width: max-content;
  height: max-content;
}

.popper {
  width: max-content;
  height: max-content;
  background: white;
  border: 1px solid $palette--tint-3-grey;
  box-sizing: border-box;
  box-shadow:
    0px 15px 25px rgba(59, 55, 86, 0.03),
    0px 50px 125px rgba(59, 55, 86, 0.07);
  border-radius: 7px;
}

.popper-enter {
  opacity: 0.01;
}

.popper-enter-active {
  opacity: 1;
  transition: opacity 350ms;
}

.popper-exit {
  opacity: 1;
}

.popper-exit-active {
  opacity: 0.01;
  transition: opacity 250ms;
}

.popper-inner {
  padding: 20px;
  width: max-content;
  height: max-content;
}

.popper .popper__arrow {
  width: 0;
  height: 0;
  border-style: solid;
}

.popper[data-placement^="right"] {
  margin-left: 12px;
}

.popper[data-placement^="right"] .popper__arrow {
  border-color: white;
  left: -6px;
  border-width: 6px;
  box-shadow: -1px -1px 1px rgba(59, 55, 86, 0.07);
}

.popper[data-placement^="top"] {
  margin-bottom: 12px;
}

.popper[data-placement^="top"] .popper__arrow {
  border-width: 6px;
  border-color: white;
  bottom: -6px;
  box-shadow: -1px 1px 1px rgba(59, 55, 86, 0.07);
}

.popper[data-placement^="bottom"] {
  margin-top: 12px;
}

.popper[data-placement^="bottom"] .popper__arrow {
  top: -6px;
  border-width: 6px;
  border-color: white;
  box-shadow: 1px -1px 1px rgba(59, 55, 86, 0.07);
}

.popper[data-placement^="left"] {
  margin-right: 12px;
}

.popper[data-placement^="left"] .popper__arrow {
  border-color: white;
  right: -6px;
  border-width: 6px;
  box-shadow: 1px 1px 1px rgba(59, 55, 86, 0.07);
}

.popper[data-x-out-of-boundaries] {
  display: none;
}
