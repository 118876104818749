.Input {
  width: 100%;
  font-family: $primary-font-stack;
  @include ms-font-size(minus1);
  border: $border-input;
  border-radius: $border-radius--small;
  padding: 0 $spacing--small;
  transition: border-color ease 100ms;
  height: 45px;
  line-height: normal;

  &:disabled {
    background: $color-input-disabled;
  }

  &::-ms-clear {
    display: none;
  }

  &:hover {
    border-color: darken($color-input, 10);
  }

  &:focus,
  &--focused,
  &--focused:hover {
    outline: 0;
    border-color: $color-brand;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color-text-light-gray;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color-text-light-gray;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color-text-light-gray;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-text-light-gray;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-text-light-gray;
  }

  &--borderless {
    background: transparent;
    border: 0;
    padding: 0;
    line-height: $line-height--medium;
  }

  &--hideSpinners {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.Input-wrapper--small {
  .Input-affixWrapper,
  .Input {
    max-width: 150px;
  }

  .Input-affixWrapper .Input {
    width: 100%;
  }
}

.Input-wrapper--medium {
  .Input-affixWrapper,
  .Input {
    max-width: 300px;
  }

  .Input-affixWrapper .Input {
    width: 100%;
  }
}

.Input-wrapper--center {
  .Input-affixWrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

.Input-label {
  display: block;
  @include ms-font-size(minus1);
  line-height: $line-height--medium;
  color: $color-text-gray;
  cursor: pointer;
  text-align: left;
  position: relative;

  &-tip {
    display: block;
    font-size: $font-size--g;
    color: $palette--grey;
    line-height: $line-height--micro;
    margin-bottom: $spacing--base * 2;
  }
}

.Input-affixInputWrapper {
  flex: 1 1 auto;
}

.Input-affixWrapper {
  display: flex;
  border: $border-input;
  border-radius: $border-radius--small;
  transition: border-color ease 100ms;
  height: 45px;

  .Input-wrapper--borderless & {
    border: 0;
    padding: 0;
    background: transparent;
  }

  .Input {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  &:hover {
    border-color: darken($color-input, 10);
  }

  &--focused,
  &--focused:hover {
    border-color: $color-brand;
  }

  .Input-prefix,
  .Input-suffix {
    // flex: 1 1 auto;
    align-self: center;
    @include ms-font-size(minus1);
    line-height: $line-height--micro;

    .Input-wrapper--borderless & {
      line-height: $line-height--medium;
    }
  }

  .Input-prefix {
    padding-left: $spacing--small;

    .Input-wrapper--borderless & {
      padding-left: 0;
      padding-right: $spacing--micro;
    }
  }

  .Input-suffix {
    padding-right: $spacing--small;

    .Input-wrapper--borderless & {
      padding-right: 0;
      padding-left: $spacing--micro;
    }
  }
}
