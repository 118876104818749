.InputText {
  display: flex;
  flex-direction: column;
  position: relative;

  @include gt(small) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.InputText-adornment {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  pointer-events: auto;
  z-index: 1;
}

.InputText-input {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  font-family: $primary-font-stack;
  line-height: 135%;
  appearance: none;

  padding: $spacing--base * 3 $spacing--base * 4;
  width: 100%;
  min-width: 100%;

  color: $palette--black;
  background: $palette--tint-1-grey;

  border: 1px solid $palette--tint-4-grey;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;

  &:hover {
    background-color: $palette--tint-2-grey;
    border: 1px solid $palette--tint-4-grey;
  }

  &:focus {
    background-color: $palette--pale-purple;
    border: 1px solid $palette--purple;

    &::placeholder {
      color: rgba($palette--purple, 0.3);
    }
  }

  &::placeholder {
    color: $palette--silver;
  }

  &--withAdornment {
    padding-right: 40px;
  }

  &--error {
    background: $palette--pale-red;
    border: 1px solid $palette--indian-red;

    &:hover {
      background: $palette--pale-red;
      border: 1px solid $palette--indian-red;
    }

    &:focus {
      background: $palette--pale-red;
      border: 1px solid $palette--indian-red;
      &::placeholder {
        color: rgba($palette--indian-red, 0.3);
      }
    }

    &::placeholder {
      color: rgba($palette--indian-red, 0.3);
    }
  }

  @include gt(small) {
    margin-top: 0;
  }
}

.InputText-label {
  display: inline-block;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  color: $palette--black;
  padding-right: 20px;
  white-space: nowrap;
}
