@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.Accordion-headline {
  text-transform: uppercase;
  @include typo--label-text;
  margin-bottom: $spacing--base * 2;
}

.Accordion-details {
  padding: $spacing--base 0;
  border-bottom: 1px solid $palette--tint-4-grey;
  background-position: bottom;
  word-break: break-word;
  position: relative;
  transition:
    border-color 150ms,
    background 150ms;
  margin-top: -1px;
  border-top: 1px solid transparent;

  .non-touch &:hover,
  .non-touch &:focus-within {
    border-bottom-color: transparent;
    border-top-color: #fff;
    z-index: 1;
    background: $palette--tint-1-grey;
    margin-left: -$spacing--base * 4;
    padding-left: $spacing--base * 4;
    margin-right: -$spacing--base * 4;
    padding-right: $spacing--base * 4;
    border-radius: $border-radius--medium;
  }

  .non-touch &[open]:hover,
  .non-touch &[open]:focus-within {
    border-top-color: transparent;
    border-bottom-color: $palette--tint-4-grey;
    background: transparent;
    border-radius: 0;
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
  }

  &:last-of-type {
    margin-bottom: $spacing--base * 4;

    @include gt($homePage-breakpoint--small) {
      margin-bottom: $spacing--base * 5;
    }
  }
}

.Accordion-details-answer {
  margin-bottom: $spacing--base * 4;
  word-break: break-word;
  white-space: pre-line;
  max-width: 1000px;

  .Accordion-details[open] & {
    animation: fadeInAndDrop 0.2s ease-out;
  }
}

.Accordion-details-summary {
  display: block;

  &::-webkit-details-marker {
    display: none;
  }
}

.Accordion-details-summary {
  cursor: pointer;
  padding: $spacing--base * 4 0;
  width: 100%;
  border: 1px solid transparent;

  // Override focus to avoid weird jump due
  // to the Accordion-details-summary-arrowContainer
  // animation
  &:focus {
    outline: none;
  }
}

.Accordion-details-summary-wrapper {
  display: flex;
  justify-content: space-between;
  @include typo--heading-4;

  @include gt($homePage-breakpoint--small) {
    @include typo--heading-2;
  }
}

.Accordion-details-summary-subtitle {
  @include typo--body-2;
}

.Accordion-details-summary-arrowContainer {
  background: $palette--tint-2-grey;
  min-width: 26px;
  min-height: 26px;
  width: 26px;
  height: 26px;
  margin-left: $spacing--base * 2;
  border: 1px solid $palette--tint-3-grey;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;

  .Accordion-details[open] & {
    transform: rotate(180deg) translateZ(0);
    transform-origin: center;
  }

  &:focus {
    outline: none;
  }
}

.Accordion-details-summary-arrowContainer-arrow {
  border-color: $palette--black transparent transparent;
  border-style: solid;
  border-width: $spacing--base 4px 4px;
  transform-origin: center center;
  margin-top: $spacing--base;

  &:focus {
    outline: none;
  }
}

.Accordion-viewMore {
  display: inline-flex;
  align-items: center;
  padding: ($spacing--base * 2) ($spacing--base * 4);
  margin-left: -($spacing--base * 4);
  border-radius: 30px;
  transition: background 150ms;

  .non-touch &:hover {
    background: $palette--lighter-pink;
  }
}

.Accordion-viewMore-text {
  @include typo--heading-4;
  color: $palette--purple;
  margin-right: 7px;

  @include gt($homePage-breakpoint--small) {
    @include typo--heading-2;
    color: $palette--purple;
  }
}

@keyframes fadeInAndDrop {
  0% {
    opacity: 0;
    transform: translateY(-$spacing--base);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
