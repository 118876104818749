.DateTextInput {
  display: inline-block;
  position: relative;

  &--fullWidth {
    width: 100%;
  }

  &-inputs {
    display: block;
    text-align: center;
    border: $border-input;
    border-radius: $border-radius--small;
    padding: 12.5px $spacing--small;
    line-height: $line-height--micro;
    transition: border-color ease 100ms;

    &:hover {
      border-color: darken($color-input, 10);
    }

    .DateTextInput--focused & {
      border-color: $color-brand;
    }

    .DateTextInput--fullWidth & {
      width: 100%;
      text-align: left;
    }

    &--inlineLabel {
      display: flex;
    }
  }

  input {
    font-family: $primary-font-stack;
    @include ms-font-size(minus1);
    border: 0;
    padding: 0;
    width: 0;
    line-height: $line-height--micro;

    &:focus {
      outline: 0;
    }
  }

  &-cancelButton {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: 0;
    padding: 0 5px;
    cursor: pointer;

    &:before {
      font-size: 0.6em;
      line-height: 1;
      @include icon($icon--close);
    }
  }

  &-seperator {
    margin: 0 0.1em;
    color: $color-light;
  }

  &-label {
    display: block;
    @include ms-font-size(minus1);
    line-height: $line-height--medium;
    color: $color-text-gray;
    cursor: pointer;
  }

  &--disabled {
    background-color: $color-input;

    input {
      background-color: $color-input;
    }
  }

  &--jumbo {
    input {
      @include ms-font-size(2);
      line-height: $line-height--medium;
    }
  }

  &--borderless {
    .DateTextInput-inputs {
      border: 0;
      padding: 0;
    }

    input {
      background: transparent;
    }
  }

  &-inlineLabel {
    flex: 1;
    align-self: center;
    @include ms-font-size(minus1);
    line-height: $line-height--micro;
    text-align: left;
    cursor: pointer;
  }
}

.DateTextInput--showingPlaceholder {
  * {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }

  .DateTextInput-placeholder {
    width: 100%;
    display: block;
    text-align: left;
    opacity: 1;
    height: auto;
    position: relative;
  }
}

.DateTextInput-placeholder--hidden {
  display: none;
}
