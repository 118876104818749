.Popover-card {
  background: white;
  border: 2px solid $color-input;
  border-radius: 7px;
  padding: 1.5em;
  pointer-events: all;
}

.Popover--position-bottom-left,
.Popover--position-bottom-right {
  margin-top: 5px;
}

.Popover--position-top-left,
.Popover--position-top-right {
  margin-top: -5px;
}
