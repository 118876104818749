@import "./colors.scss";

/**
 * Typography
 * ==========
 */

$primary-font-stack: "Poppins", sans-serif;
$input-font-stack: "Roboto", sans-serif;

// Typography 2020 © Romano
// Should be in sync with figma typography

@mixin typo--heading-1 {
  /* Heading One */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: $palette--silver;
}

@mixin typo--heading-2 {
  /* Heading Two - 24 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: $palette--black;
}

@mixin typo--heading-3 {
  /* Heading three - 20 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: $palette--black;
}

@mixin typo--heading-4 {
  /* Heading Four - 16 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $palette--black;
}

@mixin typo--heading-5 {
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

@mixin typo--body-1 {
  /* Body One - 16 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $palette--black;
}

@mixin typo--body-2 {
  /* Body Two - 14 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $palette--dark-grey;
}

@mixin typo--body-3 {
  /* Body Three - 12 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $palette--black;
}

@mixin typo--label-text {
  /* Label - 14 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: $palette--grey;
}

@mixin typo--label {
  /* Label - 12 */
  font-family: $primary-font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  text-transform: uppercase;
  color: $palette--dark-grey;
}

/*
  Modular Scale for Typography (https://www.gridlover.net)
  Base: 16
  Line height: 1.85
  Scale: 1.2 (Minor Third)
 */

$ms-base: 16px;
$ms-line-height: 30px; //16px * 1.85

$ms-points: (
  ms-minus3: 10px,
  ms-minus2: 12px,
  ms-minus1: 14px,
  ms-0: 16px,
  ms-1: 19px,
  ms-2: 23px,
  ms-3: 28px,
  ms-4: 33px,
  ms-5: 40px,
  ms-6: 48px,
  ms-7: 57px,
  ms-8: 69px,
);

@mixin ms-font-size($point) {
  font-size: map-get($ms-points, ms-#{$point});
}

/*
  Font weights
 */

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--medium: 500;
$font-weight--bold: 600;

/*
  Shared Font Sizes - should replace this with ms-font-size helper!
 */

$font-size: map-get($ms-points, ms-0);
$font-size--a: map-get($ms-points, ms-4);
$font-size--b: map-get($ms-points, ms-3);
$font-size--c: map-get($ms-points, ms-2);
$font-size--d: map-get($ms-points, ms-1);
$font-size--e: map-get($ms-points, ms-0);
$font-size--f: map-get($ms-points, ms-minus1);
$font-size--g: map-get($ms-points, ms-minus2);
$font-size--h: map-get($ms-points, ms-minus3);

$font-size--landing-title: map-get($ms-points, ms-6);

/*
  Line heights
 */

$line-height--jumbo: 60px;
$line-height--large: 45px;
$line-height--medium: 30px;
$line-height--small: 22.5px;
$line-height--micro: 15px;
