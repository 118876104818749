.ErrorMessage {
  color: $color-text-light;
  border-radius: $border-radius--medium;
  padding: $spacing--micro $spacing--small;
  margin: 0 0 $spacing--medium;
  min-height: 45px;
  display: flex;
  align-items: center;

  &.ErrorMessage--red {
    background: $color-danger;
  }

  &.ErrorMessage--purple {
    background: $palette--purple;
  }

  a {
    text-decoration: underline;
  }
}
