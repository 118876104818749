.StartCreateBookingWizardForm {
  width: 300px;
  border-radius: 7px 7px 0 0;
  background: $palette--pure-white;
  padding: 30px 20px;
  z-index: 10;
  box-shadow:
    0px 5px 5px rgba(59, 55, 86, 0.03),
    0px 0px 20px rgba(55, 57, 86, 0.1);

  @include lt(large) {
    height: 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    padding: 0;
  }

  &-error {
    text-align: center;
    margin-top: 1rem;
    color: $color-error;
    font-size: 0.8em;
  }

  .FormHelpText {
    margin-bottom: 0;
    text-align: center;
  }

  .ListingTenantServiceFee {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    & .ListingTenantServiceFee-title {
      display: flex;
      align-items: center;
      font-size: $font-size--g;
      gap: 4px;
      color: $palette--dark-grey;
    }
  }
}

.StartCreateBookingWizardForm-bookingDurationTooltip {
  font-size: 0.8em;
  background: rgba(black, 0.8);
  color: white;
  padding: 0.5em 0.75em;
  border-radius: 2px;
  animation: appear-delayed 300ms;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.Popover {
    &--position-top-right,
    &--position-top-left,
    &--position-top-center {
      margin-top: -0.25em;
    }
  }

  &.Popover {
    &--position-bottom-right,
    &--position-bottom-left,
    &--position-bottom-center {
      margin-top: 0.25em;
    }
  }
}

@keyframes appear-delayed {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.StartCreateBookingWizardForm-previouslyRequestedMessage {
  @include ms-font-size(minus1);
  color: $color-success;
  margin: $spacing--small auto;
  margin-bottom: 0;
  line-height: $line-height--small;
  text-align: center;

  &::before {
    color: $color-success;
    @include icon($icon--circled-tick);
    margin-right: 5px;
  }
}

.StartCreateBookingWizardFormDateRangePicker-label {
  @include typo--label;
}

.StartCreateBookingWizardFormDateRangePicker-trigger {
  border: 1px solid $palette--tint-4-grey;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 40px;
  padding: 5px;
}

.StartCreateBookingWizardFormDateRangePicker {
  .popper-reference {
    width: 100%;
  }
  .popper-InnerReference {
    width: inherit;
  }
  // keep date picker popup at the bottom when user scrolls up
  .popper[data-placement^="top"],
  .popper[data-placement^="bottom"] {
    inset: 0px 0px auto auto !important;
    transform: translate3d(-20px, 161px, 0px) !important;
    margin-top: 7px;
  }
}

.StartCreateBookingWizardFormDateRangePicker-previewDateButton {
  @include typo--heading-4;
  align-items: center;
  background-color: transparent;
  border: none;
  color: $palette--black;
  cursor: pointer;
  display: inline-flex;
  line-height: 22px;
  padding: 2px 5px;

  &--cleared {
    color: $palette--black;
  }

  &--active {
    color: $palette--light-purple;
    background-color: $palette--pale-purple;
  }

  &--selected {
    color: $palette--purple;
    background-color: $palette--pale-purple;
    border-radius: 5px;
  }

  &--applied {
    &:hover {
      color: $palette--grey;
    }
  }
}

.StartCreateBookingWizardFormDateRangePicker-dateSeparator {
  color: $palette--black;
  font-weight: 600;

  &--inactive {
    color: $palette--silver;
  }
}

.StartCreateBookingWizardFormDateRangePicker-ctaPanel {
  justify-content: space-between;
}

.StartCreateBookingWizardForm-SubmitButton,
.StartCreateBookingWizardForm-ChangeDateButton {
  width: 100%;
  display: block;
}

.StartCreateBookingWizardForm-ChangeDateButton {
  margin-top: 15px;
}
