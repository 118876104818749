@import "../../../styles/variables.scss";

.FlexibleDates {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-bottom: 40px;
  background: $palette--pure-white;
  width: 100%;
  @include typo--body-2;

  &--mobile {
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: fixed;
    bottom: 70px;
    padding: 20px 50px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    z-index: 1000;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-label {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 100px;
    padding: 10px 20px;
    border: 1px solid $palette--tint-4-grey;
    background: $palette--tint-1-grey;
    color: #000;
    line-height: 1;
    white-space: nowrap;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    cursor: pointer;
  }

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;

    &:disabled + label {
      cursor: not-allowed;
    }

    &:checked + label {
      background: $palette--pale-purple;
      border: 1px solid $palette--wunder-brand-purple;
      border-radius: 30px;
      color: $palette--wunder-brand-purple;
    }
  }
}

.FlexibleDatesPreview {
  display: inline-flex;
  align-items: center;

  font-size: 12px;
  padding: 0 2px;
}

.PlusMinus {
  color: inherit;
  font-size: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    line-height: 0;

    &:first-child {
      margin-bottom: 5px;
    }
  }
}
