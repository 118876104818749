.Modal {
  animation: appear 200ms;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: $color-brand-gradient-transparent;
  -webkit-overflow-scrolling: touch;

  &--centered {
    display: flex;
    align-items: flex-start;
    @include gt(medium) {
      align-items: center;
    }
  }

  &--transparentBackground {
    @include gt(medium) {
      background-image: none;
    }

    .Modal-body {
      .btn-close {
        top: 18px;
        right: 12px;
        &::before {
          color: $palette--black-gray;
        }
        @include lt(xsmall) {
          top: 36px;
          right: 30px;
        }
      }
    }

    .Modal-footer {
      padding: $spacing--medium 0 0;
      color: inherit;
    }

    .Modal-footer-link {
      color: $palette--facebook-blue;
    }
  }

  &-body {
    position: relative;
    transform: translate(0, 0);
    margin: ($spacing--base * 7) auto;
    width: 370px;

    &--top-anchored {
      top: $spacing--medium;
    }

    .btn-close {
      position: absolute;
      right: -22px;
      top: -22px;
      cursor: pointer;
      font-size: 0;
      line-height: 0;
      background: none;
      border: 0;

      &::before {
        @include icon($icon--close);
        color: white;
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        @include lt(xsmall) {
          color: $palette--black-gray;
        }
      }
    }
  }

  &-card {
    animation: scale-in 200ms;
    background: white;
    box-shadow: $box-shadow-modal;
    border-radius: 7px;
    padding: 30px;

    a:not(.btn) {
      color: $color-brand;
      text-decoration: underline;
    }

    .separator {
      position: relative;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;

      .title {
        position: relative;
        font-size: $font-size--f;
        color: $color-light;
        display: inline-block;
        background: white;
        padding: 0 10px;
        margin: 0;
      }

      &::before {
        position: absolute;
        width: 100%;
        top: 50%;
        display: block;
        border-top: 1px solid $color-input;
        font-size: 0;
        line-height: 0;
        content: "";
      }
    }
  }

  &-footer {
    text-align: center;
    padding: 30px 0;
    font-size: $font-size--f;
    color: white;

    &-link {
      padding: 10px;
      font-weight: 600;
      color: $color-action-light;
    }
  }

  @include lt(xsmall) {
    &-body {
      padding: 30px 10px 0 10px;
      width: 100%;

      .btn-close {
        top: 36px;
        right: 30px;
        padding: 10px;
      }
    }
  }

  @media only screen and (max-height: 600px) {
    &-body {
      top: 10px;
      transform: translate(0, 0);
    }
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.Modal-roundCloseButton {
  position: absolute;
  right: $spacing--small;
  top: $spacing--small;
  z-index: 5;
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
