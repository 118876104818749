@import "./ToggleButton/ToggleButton";
@import "./AppHeaderDrawer/AppHeaderDrawer";
@import "./AppHeaderDesktopUserMenu/AppHeaderDesktopUserMenu";

.AppHeaderUserMenu {
  position: relative;

  .Avatar {
    @include lt(medium) {
      display: inline-flex;
    }
  }
}

.AppHeaderNavigation-ListItem-signInLink {
  display: none;
  margin-left: $spacing--small;

  @include gt(large) {
    display: block;
  }
}

.AppHeader-Link-inviteLandlords-credit {
  display: flex;
}

.AppHeader-Link-inviteLandlords-creditText {
  font-size: $font-size--g;
  font-weight: $font-weight--medium;
  line-height: 18px;
  color: $palette--dark-grey;
  padding-right: 2px;
}

.AppHeader-Link-inviteLandlords-creditValue {
  font-size: $font-size--g;
  font-weight: $font-weight--medium;
  line-height: 18px;
  color: $palette--black;
}
