.CardButtons {
  display: flex;

  .CardButton {
    flex: 1 1 0;

    &:first-child {
      border-radius: 0 0 0 $border-radius--medium;
    }

    &:last-child {
      border-radius: 0 0 $border-radius--medium 0;
    }
  }
}
