.ListingPrice {
  display: inline-block;
  background: $color-brand-dark;
  color: white;
  line-height: 1em;
  border-radius: 2em;
  padding: 0.35em 0.825em;
  font-weight: bold;

  &--light {
    padding-left: 0;
    color: $color-text;
    background: transparent;
    font-weight: $font-weight--regular;
  }

  &-time {
    color: rgba(white, 0.6);
    font-size: 12px;
    margin-left: 5px;
    position: relative;
    text-transform: lowercase;
  }

  &-Icon {
    font-style: normal;
    font-weight: normal;
    margin-right: 5px;
    font-size: $font-size--f;
  }

  &--light &-time {
    color: $palette--dark-grey;
    font-weight: $font-weight--regular;
  }
}
