.AppHeaderDesktopUserMenu {
  width: 190px;

  position: absolute;
  top: 40px;

  background-color: $palette--pure-white;
  border: 1px solid $palette--light-gray-9;
  box-shadow: $box-shadow-small-box;
  border-radius: 7px;

  padding-top: $spacing--small;
  padding-bottom: $spacing--micro;

  z-index: 10;

  &--loggedIn {
    top: 55px;
  }

  &--active {
    display: block;
  }

  @include lt(large) {
    right: $spacing--base * 2;
  }

  @include gt(large) {
    right: -$spacing--base * 5;
  }
}

.AppHeaderDesktopUserMenu--medium {
  display: none;
  right: -$spacing--base * 4;

  @include lt(large) {
    display: block;
  }
}

.AppHeaderDesktopUserMenu--large {
  display: none;

  @include gt(large) {
    display: block;
  }
}

.AppHeaderDesktopUserMenu-username {
  font-weight: $font-weight--bold;
  font-size: $font-size--e;
  color: $palette--black;

  padding: 0 $spacing--small;
}

.AppHeaderDesktopUserMenu-warning {
  font-weight: $font-weight--medium;
  font-size: $font-size--g;
  color: $palette--dark-grey;
  line-height: 18px;

  padding: 0 $spacing--small;
}

.AppHeaderDesktopUserMenu-LinkList {
  margin: 0 $spacing--base;

  &--loggedIn {
    margin-top: $spacing--small;
  }

  .AppHeader-Link {
    font-size: $font-size--f;
    font-weight: $font-weight--medium;
    padding: 8px $spacing--base * 2 0 $spacing--base * 2;
    border-radius: $border-radius-base;
    flex-wrap: wrap;

    &:after {
      content: "";
      width: 100%;
      border-bottom: 1px solid $palette--tint-2-grey;
      margin-top: 7px;
      z-index: -1;
    }
  }

  .AppHeader-Link:hover {
    background: $palette--tint-1-grey;
  }

  .AppHeader-Link-text {
    color: $palette--black;
  }

  .AppHeader-Link-badge {
    background: $palette--wunder-purple;
    color: $palette--pure-white;
  }

  // Logout link
  .AppHeader-Link-logoutLink:hover {
    background: $palette--pale-red-1;
  }

  .AppHeader-Link-logoutLink .AppHeader-Link-text {
    color: $palette--indian-red;
  }
}

.AppHeaderDesktopUserMenu-LinkList-item {
  margin-top: -1px;

  &:last-child > .AppHeader-Link::after {
    border: none;
  }
}

.AppHeaderDesktopUserMenu-separator {
  border: 0;
  border-top: 3px solid $palette--tint-2-grey;
  margin: $spacing--base 0;
}

.AppHeaderDesktopUserMenu--signInButton {
  padding: 0 $spacing--base * 2;
  padding-bottom: $spacing--base;
  margin-top: $spacing--base;
  text-align: center;
}
