.Footer {
  background-color: #000000;
  color: #ffffff;

  &.unfix {
    @include gt($homePage-breakpoint--medium) {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
}

.Footer-container {
  padding: $spacing--base * 8 0 $spacing--base * 4;
  margin: 0 $spacing--base * 4;
  max-width: $homePage-content--maxWidth;

  @include gt($homePage-breakpoint--small) {
    padding: $spacing--x-large 0 $spacing--medium;
    margin: 0 36px;
  }

  @include gt($homePage-breakpoint--medium) {
    padding: $spacing--x-large 0 $spacing--medium;
    margin: 0 $spacing--base * 16;
  }

  @include gt($homePage-breakpoint--large) {
    padding: $spacing--x-large 0 $spacing--medium;
    margin: 0 96px;
  }

  @include gt($homePage-content--maxWidth + 192px) {
    padding: $spacing--x-large 0 $spacing--medium;
    margin: 0 auto;
  }
}

.Footer-container--withoutRegions {
  padding-top: 50px;
}

.Footer-logoAndLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include gt($homePage-breakpoint--medium) {
    margin-top: 140px;
    padding-bottom: $spacing--base * 2;
    border-bottom: 1px solid #ffffff26;
  }

  .logo {
    fill: #fff;
  }
}

.Footer-logoAndLinks--withoutRegions {
  @include gt($homePage-breakpoint--medium) {
    margin-top: 0px;
  }
}

.Footer-companyLinks {
  display: none;

  @include gt($homePage-breakpoint--medium) {
    display: flex;
  }
}

.Footer-companyLinkItem {
  margin-left: $spacing--base;

  .Footer-link {
    height: $button-height--small;
    padding: 0 $spacing--base * 2;
    border-radius: 5px;

    &:hover {
      background-color: #1a1a1a;
    }
  }
}

.Footer-link {
  color: #ffffff;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.Footer-socialIcon {
  height: $spacing--small;
  width: $spacing--small;
  margin-right: $spacing--small;

  @include gt($homePage-breakpoint--medium) {
    margin-right: 0;
  }
}

.Footer-socialAndLegal {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include gt($homePage-breakpoint--medium) {
    margin-top: $spacing--base * 4;
  }
}

.Footer-legal {
  display: flex;
  flex-direction: column;
  margin-top: $spacing--base * 4;

  @include gt($homePage-breakpoint--medium) {
    flex-direction: row;
    margin-top: 0;
  }
}

.Footer-legalItem {
  font-size: $font-size--g;
  white-space: nowrap;
  font-weight: $font-weight--medium;
  order: 2;
  margin-top: $spacing--base * 2;

  @include gt($homePage-breakpoint--medium) {
    margin-top: 0;
  }

  .Footer-link {
    @include gt($homePage-breakpoint--medium) {
      margin-left: $spacing--base;
      padding: 0 8px;
      height: 26px;
      border-radius: 5px;

      &:hover {
        background-color: #1a1a1a;
      }
    }
  }
}

.Footer-copyright {
  color: $palette--grey;

  @include gt($homePage-breakpoint--medium) {
    order: 1;
    margin-left: 0;
    display: flex;
    align-items: center;
  }
}

.Footer-cities {
  display: none;

  @include gt($homePage-breakpoint--medium) {
    min-height: 72px;
    display: flex;
    flex-wrap: wrap;
  }
}

.Footer-cityItem {
  @include typo--heading-2;
  line-height: 1.5;
  color: $palette--light-purple;
  margin-right: $spacing--base * 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer-cityItem--new {
  margin-left: $spacing--base;
  background-color: $palette--light-purple;
  @include typo--label-text;
  color: #000000;
  text-transform: uppercase;
  padding: 0 $spacing--base;
  border-radius: $border-radius--large * 2;
}

.Footer-accordionSection {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @include gt($homePage-breakpoint--medium) {
    display: none;
  }
}

.Footer-DestinationGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $spacing--medium;

  @include gt($homePage-breakpoint--medium) {
    display: none;
  }

  .city-name {
    font-size: $font-size--f;
    max-width: calc(50vw - 35px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.Footer-accordionListItem {
  margin-top: $spacing--base * 2;
}

.Footer-social {
  display: none;

  @include gt($homePage-breakpoint--medium) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.Footer-socialLinkItem--desktop {
  .Footer-link {
    height: $button-height;
    width: $button-height;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:hover {
      background-color: #1a1a1a;
    }
  }

  &.Footer-refugeeSupport {
    padding: 0;
    .Footer-link {
      width: auto;
      padding: 0 20px;
    }
  }
}

.Footer-refugeeSupport {
  font-size: 14px;
  border: 1px #fff solid;
  border-radius: 20px;
  width: max-content;
  padding: 7px 14px;
  font-weight: bold;
  text-transform: uppercase;

  .Footer-link {
    gap: 10px;
  }
  &--mobile {
    @include gt($homePage-breakpoint--medium) {
      display: none;
    }
  }
}

.Footer-socialLinkItem--noMarginRight {
  margin-right: 0;
}

.Footer-categories {
  @include gt($homePage-breakpoint--medium) {
    display: none;
  }
}

.Footer-listItem--noMarginTop {
  margin-top: 0;
}

#WunderflatsLogo-Footer svg.logo {
  height: auto;
  width: auto;
}

//////////////////////
// ACCORDION STYLES //
//////////////////////

.FooterAccordion-headline {
  text-transform: uppercase;
  @include typo--label-text;
  margin-bottom: $spacing--base * 2;
}

.FooterAccordion-details {
  padding: $spacing--base * 4 0;
  border-bottom: 1px solid #ffffff26;
  word-break: break-word;

  &:last-of-type {
    margin-bottom: $spacing--base * 4;

    @include gt($homePage-breakpoint--small) {
      margin-bottom: $spacing--base * 5;
    }
  }
}

.FooterAccordion-details-answer {
  margin-top: $spacing--base * 4;
  word-break: break-word;
  white-space: pre-line;

  .FooterAccordion-details[open] & {
    animation: fadeInAndDrop 0.2s ease-out;
  }
}

.FooterAccordion-details-summary {
  display: block;

  &::-webkit-details-marker {
    display: none;
  }
}

.FooterAccordion-details-summary {
  cursor: pointer;
  width: 100%;
  border: 1px solid transparent;

  &:focus {
    outline: none;
    border: 1px solid #4d90fe11;
    box-shadow: 0 0 3px #4d90fe;
    border-radius: 3px;
  }
}

.FooterAccordion-details-summary-wrapper {
  display: flex;
  justify-content: space-between;
  @include typo--heading-4;
  color: #ffffff;
  font-size: 20px;

  @include gt($homePage-breakpoint--small) {
    @include typo--heading-2;
    color: #ffffff;
  }
}

.FooterAccordion-details-summary-arrowContainer {
  background: #262626;
  min-width: 26px;
  min-height: 26px;
  width: 26px;
  height: 26px;
  margin-left: $spacing--base * 2;
  border: 1px solid #5c5c5c;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;

  .FooterAccordion-details[open] & {
    transform: rotate(180deg) translateZ(0);
    transform-origin: center;
  }

  &:focus {
    outline: none;
  }
}

.FooterAccordion-details-summary-arrowContainer-arrow {
  border-color: #ffffff transparent transparent;
  border-style: solid;
  border-width: $spacing--base 4px 4px;
  transform-origin: center center;
  margin-top: $spacing--base;

  &:focus {
    outline: none;
  }
}

.FooterAccordion-viewMore {
  display: flex;
  align-items: center;
}

.FooterAccordion-viewMore-text {
  @include typo--heading-4;
  color: $palette--purple;
  margin-right: 7px;

  @include gt($homePage-breakpoint--small) {
    @include typo--heading-2;
    color: $palette--purple;
  }
}

@keyframes fadeInAndDrop {
  0% {
    opacity: 0;
    transform: translateY(-$spacing--base);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
