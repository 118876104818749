.ImageGalleryModal {
  background: rgba(0, 0, 0, 0.92);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .Modal-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    transform: none;

    .btn-close {
      top: 12px;
      right: 12px;

      &::before {
        color: white;
      }
    }
  }

  .ImageGallery-navButton {
    img {
      width: 50px;
      height: 50px;
    }
  }
}
