.MonthlyAvailabilityViewer {
  &-yearSelect {
    margin: $spacing--small auto 0 auto;
    max-width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-currentYear {
    font-size: $font-size--c;
  }

  &-selectPreviousYear,
  &-selectNextYear {
    cursor: pointer;
    background: 0;
    border: 0;
    padding: $spacing--micro;

    &::before {
      font-size: $font-size--c;
      color: #adadad;
    }

    &:hover::before {
      color: $color-text;
    }

    &:disabled::before {
      color: $color-bg;
    }
  }

  &-selectPreviousYear::before {
    @include icon($icon--chevron-left);
  }

  &-selectNextYear::before {
    @include icon($icon--chevron-right);
  }

  &-monthsContainer {
    overflow: hidden;
    margin: 0;
    padding: 1px 0 0 1px;
    list-style: none;
  }

  &-month {
    float: left;
    overflow: hidden;
    position: relative;
    width: 16.77%;
    height: 120px;
    margin: -1px 0 0 -1px;
    border: 1px solid $color-input;
    padding: 80px 20px 20px 10px;
    text-align: right;

    @include lt(xsmall) {
      font-size: $font-size--f;
      line-height: $line-height--small;
    }

    &::after {
      content: "";
      position: absolute;
      top: 10px;
      right: 10px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &-month--available {
    &::after {
      background: $color-success;
    }
  }

  &-month--partlyAvailable {
    background: $color-bg-light;

    &::after {
      background: $color-alert;
    }
  }

  &-month--unavailable {
    background: $color-bg;

    &::after {
      background: $color-error;
    }
  }

  @media only screen and (max-width: 520px) {
    &-month {
      height: 80px;
      padding: 30px 5px 5px;
    }
  }

  @media only screen and (min-width: 521px) and (max-width: 680px) {
    &-month {
      height: 80px;
      padding: 40px 5px 5px;
    }
  }
}
