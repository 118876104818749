.tableGrid {
  &-row {
    width: 100%;
    display: table;
    table-layout: fixed;

    &--layoutAuto {
      table-layout: auto;
    }
  }

  &-column {
    display: table-cell;
    vertical-align: top;
  }
}
