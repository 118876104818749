.FullWidthCard {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1002;

  &.isClosed {
    transform: translate(0, 100%);
  }

  &.isOpen {
    transform: translate(0, 0);
  }

  &.semiVisible {
    transform: translate(0, 60%);
  }
}

.FullWidthCard-buttonsOuter {
  align-items: center;
  display: flex;
  margin-bottom: $spacing--small;
  position: relative;
}

.FullWidthCard-actionButton {
  margin-left: auto;
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  &.actionButtonUp {
    transform: translate(-50%, -140%);
  }
}

.FullWidthCard-closeButton {
  border-radius: $border-radius--large * 2;
  border: none;
  box-shadow: $box-shadow-not-so-slight;
  background-color: #fff;
  width: $button-height--small;
  line-height: $button-height--small;
  height: $button-height--small;
  margin-right: $spacing--small;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FullWidthCard-cardOuter {
  background-color: #fff;
  height: 250px;
}
