@mixin labelOnTop {
  grid-template-columns: 1fr;
  grid-template-rows: auto $spacing--base * 2 auto 18px;
  grid-template-areas:
    "label"
    "."
    "children"
    "error";
}

.InputWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 18px;
  grid-template-areas:
    "label children"
    ". error";
  font-size: $font-size--f;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;

  @include lt(large) {
    @include labelOnTop;
  }
}

.InputWrapper--labelOnTop {
  @include labelOnTop;
}

.InputWrapper-label-container {
  grid-area: label;
  align-self: center;
  cursor: pointer;
}

.InputWrapper-label {
  display: block;
  margin-right: 20px;
}

.InputWrapper-label-sub {
  display: block;
  font-size: $font-size--g;
  color: $palette--grey;
  line-height: $line-height--micro;
  margin-right: 20px;
}

.InputWrapperPhone .InputWrapper-label-sub {
  position: absolute;
}

.InputWrapper-children {
  grid-area: children;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  @include lt(large) {
    @include labelOnTop;
  }
}

.InputWrapper-error {
  align-self: center;
  grid-area: error;
  background: transparent;
  color: $palette--indian-red;
  padding: 0;
  font-size: $font-size--g;
  line-height: 100%;
}
