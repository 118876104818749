.SimpleModal-visible {
  pointer-events: none;
  overflow: hidden;
}

.SimpleModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: $color-brand-gradient-transparent;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  z-index: 9999;
  pointer-events: all;
  animation: appear 200ms;

  &-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 120px);
    max-width: 1024px;
  }
}

.SimpleModal--backdropStyle_light {
  background-image: none;
  background: rgba(0, 0, 0, 0.4);
}

.SimpleModal--size_medium .SimpleModal-inner {
  max-width: 540px;
}

.SimpleModal-close {
  position: absolute;
  display: block;
  right: -22px;
  top: -22px;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  background: none;
  border: 0;
  z-index: 100;

  &::before {
    @include icon($icon--close);
    color: white;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }

  @include lt(small) {
    right: 22px;
  }

  @include lt(xsmall) {
    right: 15px;
    top: 6px;
  }
}
