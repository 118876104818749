@import "./EmailForm";
@import "./ForgotPassword";
@import "./PasswordForm";
@import "./SocialButtons";
@import "./SignupForm";
@import "./EmailVerification";

.AuthComponent-Form {
  max-width: 690px;
}

.HiddenPasswordInput {
  height: 0;
  overflow: hidden;
}

// Hide reCaptcha v3 badge
.grecaptcha-badge {
  visibility: hidden;
}

.ReCaptchaTOS {
  font-size: $font-size--g;
  line-height: $line-height--micro;
  color: $palette--grey;
  margin-top: 7px;

  &-link {
    color: $palette--black;
    font-weight: $font-weight--bold;
  }
}
