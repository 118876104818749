.ListingDetailsBrief {
  display: inline-block;
  font-size: $font-size--f;
  text-align: left;
  color: $color-text;
  white-space: nowrap;
}

.info {
  color: $palette--dark-grey;
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;

  &:not(:last-of-type)::after {
    content: "•";
    display: inline-block;
    height: 4px;
    margin: 0 8px;
    width: 4px;
  }
}

.ListingDetailsBrief-info--area {
  text-transform: lowercase;
}

.ListingDetailsBrief--normalSize {
  font-size: 16px;
}

.ListingDetailsBrief-area,
.ListingDetailsBrief-beds,
.ListingDetailsBrief-rooms {
  position: relative;
  margin-right: $spacing--medium;
  padding-left: 25px;
  white-space: nowrap;

  &::before {
    line-height: normal;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 18px;
    width: 16px;
    background-size: 18px 16px;
  }
}

.ListingDetailsBrief--verbose .ListingDetailsBrief-area,
.ListingDetailsBrief--verbose .ListingDetailsBrief-beds,
.ListingDetailsBrief--verbose .ListingDetailsBrief-rooms {
  margin-right: $spacing--small;
}

.ListingDetailsBrief-rooms::before {
  @include svgIcon($icon--room);
}

.ListingDetailsBrief-beds::before {
  @include svgIcon($icon--bed);
}

.ListingDetailsBrief-area::before {
  @include svgIcon($icon--apartment-size);
}
