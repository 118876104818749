/**
 * Spacing
 * =======
 * Variables for margins, paddings, etc
 */

$spacing--jumbo: 120px;
$spacing--x-large: 100px;
$spacing--very-large: 90px;
$spacing--large: 60px;
$spacing--medium: 30px;
$spacing--minor: 20px;
$spacing--small: 15px;
$spacing--micro: 7.5px;
$spacing--base: 5px;
