.SocialButtons {
  margin-top: $spacing--base * 7;
  animation: SocialButtonsFadeInUp $login-transition-timing
    $login-transition-function forwards;
}

.SocialButtons-title {
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  color: $palette--grey;
  margin-bottom: $spacing--base * 5;
}

.SocialButtons-buttonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.SocialButtons-Button {
  display: inline-flex;
  align-items: center;
  color: $palette--black;
  padding-right: $spacing--medium + $spacing--small;
  padding-bottom: $spacing--small;

  &:hover {
    color: $palette--dark-grey;
  }
}

.SocialButtons-Button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border: 1px solid $palette--tint-3-grey;
  background: #f0f6fe;
  border-radius: 50%;
}

.SocialButtons-Button-label {
  font-weight: $font-weight--bold;
  font-size: $font-size--e;
  margin-left: $spacing--small;
}

@keyframes SocialButtonsFadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
