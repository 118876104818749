@import "~leaflet/dist/leaflet.css";

.leaflet-popup {
  margin-bottom: 45px;
}

.leaflet-popup-content-wrapper {
  padding: 0;
  border-radius: 0;
}

.leaflet-popup-content {
  margin: 0 -1px 0 0;
}
