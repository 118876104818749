.VerticalInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 100%;
}

.VerticalInputContainer-label {
  margin-bottom: $spacing--base * 3;
  font-family: $primary-font-stack;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  color: $palette--black;
}

.VerticalInputContainer-tooltip {
  vertical-align: sub;
  padding-left: 5px;
}

.VerticalInputContainer-error {
  margin: 2px 0;
  height: $spacing--base * 3;
  font-family: $input-font-stack;
  font-size: $font-size--g;
  line-height: $line-height--micro;
  animation: appear 0.2s linear 1;
  color: $palette--indian-red;
}
