/**
 * Dimensions
 * ==========
 * Variables used for heights, widths, etc
 */

$global-width--max-width: 1200px;
$landlordlanding-content-maxwidth: 1100px;
$page-max-width: 1170px;
$page-max-width--narrow: 800px;

$height-app-header: 70px;
$height-app-header--mobile: 64px;

$listingspage-tablet-breakpoint: 1145px;

$sidebarLayout-breakpoint--max-width: 1440px;
$sidebarLayout-breakpoint--min-width: 720px;

$homePage-breakpoint--small: 720px;
$homePage-breakpoint--medium: 1000px;
$homePage-breakpoint--large: 1200px;
$homePage-breakpoint--xlarge: 1440px;
$homePage-breakpoint--xxlarge: 1920px;

$homePage-content--maxWidth: 1535px;

$profileTab-breakpoint-minWidth-twoColumnFields: 585px;

$geocodingInputSearch-appHeader-maxWidth: 340px;
$geocodingInputSearch-appHeader-maxWidth--medium: 430px;
