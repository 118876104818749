.Select {
  position: relative;
  display: inline-block;
  border: $border-input;
  border-radius: $border-radius--small;

  &--borderless {
    border: 0;
    background: none;
    padding: 0;
  }

  &--focused {
    border-color: $color-brand;
  }

  &-label {
    display: block;
    @include ms-font-size(minus1);
    line-height: $line-height--medium;
    color: $color-text-gray;
    cursor: pointer;

    &__wrapper {
      display: flex;
      align-items: end;
      justify-content: space-between;
      width: 154px;

      &__no_label {
        justify-content: flex-end;
      }
    }
  }

  &--full {
    width: 100%;
  }

  // Arrow for dropdown
  &::after {
    content: "";
    border-color: #000 transparent transparent;
    border-style: solid;
    border-width: 4px 3px 3px;
    position: absolute;
    right: 25px;
    top: calc(50% - 2px); // Optical adjustment
  }

  &-select {
    font-family: $primary-font-stack;
    @include ms-font-size(minus1);
    border: 0;
    border-radius: 0;
    padding: 0 $spacing--small;
    line-height: normal;
    height: 41px; // 45px - borders
    background: $color-bg-white;
    appearance: none;
    min-width: 150px;
    width: auto;
    position: relative;
    color: $color-text;

    &::-ms-expand {
      display: none;
    }

    &::-ms-value {
      background: $color-bg-white;
      color: $color-text;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $color-text;
    }

    &--borderless {
      border: 0;
      background: none;
      padding: 0;

      &::-ms-value {
        background: transparent;
      }
    }

    &:hover {
      border-color: darken($color-input, 10);
    }

    &:focus {
      outline: 0;
    }

    &--full {
      width: 100%;
    }

    &--placeholder-mode {
      color: darken($color-gray, 20%);
    }
  }

  select:invalid {
    color: darken($color-gray, 20%);
  }

  &--jumbo {
    .Select-select {
      @include ms-font-size(2);
      line-height: $line-height--medium;
    }
  }

  &--borderless {
    &::after {
      right: 0;
    }
  }
}

.Select-label__wrapper .Input-label-tooltipContainer {
  .popper {
    background: $palette--black;
    color: $palette--pure-white;
    border: none;
    margin-top: 5px;
    margin-left: 4px;

    &-inner {
      padding: 10px 18px;
    }

    .popper__arrow {
      border-color: $palette--black;
    }
  }

  .InputTooltip-text {
    @include typo--body-3;
    max-width: 260px;
    color: $palette--pure-white;

    @include gt(small) {
      max-width: 300px;
    }
  }
}
