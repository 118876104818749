.InputTextArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  @include gt(small) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.InputTextArea-internal {
  font-family: $primary-font-stack;
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  line-height: $line-height--medium;
  overflow: hidden;
  padding: 13px $spacing--base * 4;
  min-width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  word-break: break-word;
  width: 100%;
  @include gt(small) {
    margin-top: 0;
  }
}

.InputTextArea-hiddenSpan {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre-wrap;
}

.InputTextArea-input {
  resize: none;
  outline: none;

  color: $palette--black;
  background: $palette--tint-1-grey;

  border: 1px solid $palette--tint-4-grey;

  &:hover {
    background-color: $palette--tint-2-grey;
    border: 1px solid $palette--tint-4-grey;
  }

  &:focus {
    background-color: $palette--pale-purple;
    border: 1px solid $palette--purple;

    &::placeholder {
      color: rgba($palette--purple, 0.3);
    }
  }

  &::placeholder {
    color: $palette--silver;
  }

  &--error {
    background: $palette--pale-red;
    border: 1px solid $palette--indian-red;

    &:hover {
      background: $palette--pale-red;
      border: 1px solid $palette--indian-red;
    }

    &:focus {
      background: $palette--pale-red;
      border: 1px solid $palette--indian-red;
      &::placeholder {
        color: rgba($palette--indian-red, 0.3);
      }
    }

    &::placeholder {
      color: rgba($palette--indian-red, 0.3);
    }
  }
}

.InputTextArea-label {
  display: inline-block;
  font-weight: $font-weight--medium;
  font-size: $font-size--f;
  color: $palette--black;
  padding-right: 20px;
  white-space: nowrap;
}
