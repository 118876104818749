.VerticalRadio input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.VerticalRadio-listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing--base * 2 $spacing--base * 3;
  transition: background 0.15s;
}

.VerticalRadio-label {
  font-family: $primary-font-stack;
  font-weight: $font-weight--bold;
  font-size: $font-size--f;
  color: $palette--dark-grey;
  display: flex;
  flex-direction: column;
  background: none;
  width: 85%;
  margin-right: $spacing--small;
  height: 100%;
  justify-content: space-around;
}

.VerticalRadio-title {
  color: $palette--dark-grey;
  line-height: $line-height--small;
}

.VerticalRadio-subtitle {
  color: $palette--grey;
  font-weight: $font-weight--medium;
  line-height: $line-height--small;
}

.VerticalRadio-icon {
  width: 26px;
  height: 26px;
  border: 1px solid $palette--tint-4-grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.25s;
}

.VerticalRadio-iconInner {
  display: none;
  color: white;
}

.VerticalRadio .VerticalRadio-listItem:hover {
  background: $palette--tint-1-grey;
  border-radius: 5px;
  cursor: pointer;
}

.VerticalRadio input[type="radio"]:checked ~ .VerticalRadio-label {
  .VerticalRadio-title {
    color: $palette--black;
  }
}
.VerticalRadio input[type="radio"]:checked ~ .VerticalRadio-icon {
  border: none;
  background: $palette--wunder-purple;
  .VerticalRadio-iconInner {
    display: inherit;
  }
}
