.SearchResults {
  margin: 0 auto;
  width: calc(100% - #{$spacing--large});
  text-align: left;
  background: #fff;
  color: $color-text;
  border-radius: 0 0 7px 7px;
  position: absolute;
  top: 100%;
  left: $spacing--medium;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  max-height: 18em;

  @include lt(small) {
    max-height: none;
    overflow-y: auto;
    width: 100%;
    position: static;
    box-shadow: none;
    border-bottom: 2px solid $color-divider;
    border-radius: 0;
  }
}

.SearchResults-item {
  border-top: 1px solid $color-divider;

  &--selected {
    color: $color-brand-dark;
    background: $color-divider;
  }

  &:first-child {
    border-top: none;
  }

  &--empty {
    padding: $spacing--large $spacing--medium;

    &:hover {
      background: none;
    }

    .SearchResults-item-title {
      font-size: $font-size--c;
      text-align: center;
    }

    .SearchResults-item-body {
      font-size: $font-size--e;
      line-height: 1.6;
      text-align: center;
    }
  }
}

.SearchResults-item-title {
  font-size: $font-size--e;
  font-weight: normal;
  margin: 0;

  @include lt(small) {
    font-size: $font-size--f;
  }
}

.SearchResults-link {
  display: block;
  padding: $spacing--small $spacing--medium;

  @include lt(small) {
    padding: $spacing--small;
  }
}
