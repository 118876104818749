.ForgotPassword {
  display: inline-flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  height: 40px;
}

.ForgotPassword-padlock {
  margin-right: $spacing--base * 2;
}

.ForgotPassword-label {
  font-size: $font-size--g;
  color: $color-2019--font-secondary;
  font-weight: $font-weight--medium;
  line-height: 16px;
  font-family: $primary-font-stack;
}

.ForgotPassword-popover-ctaButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ForgotPassword-popover-btn {
  flex: 1;
  height: 40px;
}

.ForgotPassword-popover-closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
}

.ForgotPassword-popover-title-less-margin {
  font-size: $font-size--d;
  font-weight: $font-weight--bold;
  font-family: $primary-font-stack;
  line-height: 25px;
  margin-bottom: 15px;

  @media (width > 700px) {
    max-width: 285px;
  }
}

.ForgotPassword-popover-text-less-wide {
  @include typo--body-2;
  margin-bottom: 45px;

  @media (width > 700px) {
    max-width: 285px;
  }
}

// If the email is too long to fit the popover, this will wrap it into new line
.ForgotPassword-popover-email {
  word-break: break-all;
}

// TODO remove/replace with .ForgotPassword-popover-title-less-margin
.ForgotPassword-popover-title {
  font-size: $font-size--d;
  font-weight: $font-weight--bold;
  font-family: $primary-font-stack;
  line-height: 25px;
  margin: 24px 0 10px 0;
  max-width: 260px;
}

// TODO remove/replace with .ForgotPassword-popover-text-less-wide
.ForgotPassword-popover-text {
  font-size: $font-size--f;
  font-weight: $font-weight--medium;
  font-family: $primary-font-stack;
  line-height: 21px;
  margin-bottom: $spacing--medium + $spacing--base;
  max-width: 340px;
}

.AuthComponent-Form-PasswordForm .popper-reference {
  display: inline-flex;
}

.ForgotPasswordView .popper-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 350px;
}

.ForgotPasswordView .Toast-content {
  overflow: hidden;
}

.ForgotPasswordView .Toast .StatusCard-outer {
  box-shadow:
    0px 50px 125px rgba(59, 55, 86, 0.07),
    0px 15px 25px rgba(59, 55, 86, 0.03);
}

.ForgotPasswordView .StatusCard .StatusCard-inner {
  padding: $spacing--small;
}

.ForgotPasswordView .Drawer-children {
  padding: $spacing--base * 2 $spacing--medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Drawer-children .ForgotPassword-popover-title {
  margin-top: 0;
}

.Drawer-children .ForgotPassword-popover-ctaButtons {
  margin-top: $spacing--base * 5;
}
