.PhoneNumber {
  display: inline-block;
  color: $color-brand;

  &-phoneIcon:before {
    @include icon($icon--telephone);
    font-style: normal;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    display: inline-block;
    color: $color-brand;
    margin-right: 5px;
  }

  &--subtle {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  &--transparent,
  &--transparent &-phoneIcon:before {
    color: white;
  }
}
